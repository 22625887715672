import React, { useState, useEffect } from "react";
import { default_icon } from "../../config";
import { motion, AnimatePresence } from "framer-motion";
import {
  MagnifyingGlassIcon,
  AdjustmentsVerticalIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { auth, backEndUrl, backApiKey } from "../../config";

const GameLibrary = ({ games, onGameClick, loading, onRemoveGame }) => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showGenres, setShowGenres] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [fetchingGames, setFetchingGames] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [gameToRemove, setGameToRemove] = useState(null);

  useEffect(() => {
    setFetchingGames(loading);
  }, [loading]);

  useEffect(() => {
    const delay = setTimeout(() => {
      setFetchingGames(false);
    }, 2000);
    return () => clearTimeout(delay);
  }, [selectedGenres, searchQuery]);

  const filteredGames = games
    .filter((game) => {
      if (selectedGenres.length === 0) return true;
      return (
        game.genres &&
        selectedGenres.some((genre) => game.genres.includes(genre))
      );
    })
    .filter((game) =>
      game.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const genres = Array.from(
    new Set(games.flatMap((game) => game.genres))
  ).sort();

  const handleGenreClick = (genre) => {
    setSelectedGenres((prev) =>
      prev.includes(genre) ? prev.filter((g) => g !== genre) : [...prev, genre]
    );
  };

  const handleRemoveGame = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user set.");
        return;
      }

      const response = await fetch(`${backEndUrl}/users/${user.uid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": backApiKey,
        },
        body: JSON.stringify({
          userInfo: {
            gameIds: games.filter((g) => g.id !== gameToRemove.id).map((g) => g.id),
          },
        }),
      });

      if (response.ok) {
        console.log("Game removed from library successfully.");
        // Update local state
        onRemoveGame(gameToRemove.id);
        setShowRemoveModal(false);
        setGameToRemove(null);
      } else {
        console.error("Error removing game from library:", response.statusText);
      }
    } catch (error) {
      console.error("Error removing game from library:", error);
    }
  };

  return (
    <div className="bg-gray-600/20 text-white min-h-screen p-6 rounded-lg">
      <div className="max-w-7xl mx-auto">
        <div className="mb-8">
          <div className="flex items-center space-x-4">
            <div className="relative flex-grow">
              <input
                type="text"
                placeholder="Search games..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full bg-gray-600/20 text-white rounded-lg py-2 pl-10 pr-4 focus:outline-none focus:ring-2 focus:ring-purple-600"
                disabled={fetchingGames}
              />
              <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            </div>
            <button
              className={`bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 flex items-center ${
                fetchingGames ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => setShowGenres(!showGenres)}
              disabled={fetchingGames}
            >
              <AdjustmentsVerticalIcon className="h-5 w-5 mr-2" />
              Filter
            </button>
          </div>
        </div>

        <AnimatePresence>
          {showGenres && (
            <motion.div
              initial={{ opacity: 1, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className="bg-gray-600/20 rounded-lg p-4 mb-8 overflow-hidden"
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Genres</h3>
                {selectedGenres.length > 0 && (
                  <button
                    className="text-sm font-bold text-purple-400 hover:text-purple-300 flex items-center"
                    onClick={() => setSelectedGenres([])}
                    disabled={fetchingGames}
                  >
                    <XMarkIcon className="h-4 w-4 mr-1" />
                    Clear All
                  </button>
                )}
              </div>
              <div className="flex flex-wrap gap-2">
                {genres.map((genre) => (
                  <button
                    key={genre}
                    className={`px-3 py-1 rounded-full text-sm ${
                      selectedGenres.includes(genre)
                        ? "bg-purple-600 text-white"
                        : "bg-gray-400/20 text-gray-300 hover:bg-gray-600"
                    }`}
                    onClick={() => handleGenreClick(genre)}
                    disabled={fetchingGames}
                  >
                    {genre}
                  </button>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <div className="bg-gray-600/20 rounded-lg p-4">
          {fetchingGames ? (
            <div className="text-center py-8">
              <svg
                className="animate-spin h-8 w-8 text-purple-600 mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p className="mt-2">Fetching games...</p>
            </div>
          ) : filteredGames.length === 0 ? (
            <div className="text-center py-8">No matching games</div>
          ) : (
            <div className="space-y-4">
              {filteredGames.map((game) => (
                <motion.div
                  key={game.id}
                  className={`flex items-center p-4 rounded-lg cursor-pointer transition duration-300 ${
                    game === selectedGame
                      ? "bg-purple-700"
                      : "bg-gray-700 hover:bg-gray-600"
                  }`}
                  onClick={() => onGameClick(game)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <img
                    className="w-16 h-16 rounded-lg object-cover mr-4"
                    src={game.gameIcon}
                    onError={(event) => {
                      event.target.src = default_icon;
                      event.onerror = null;
                    }}
                    alt={game.title}
                  />
                  <div className="flex-grow">
                    <h3 className="font-medium text-lg">{game.title}</h3>
                    <p className="text-sm text-gray-400">
                      {game.genres.join(", ")}
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      setShowRemoveModal(true);
                      setGameToRemove(game);
                    }}
                    className="ml-4 bg-red-500 text-white rounded-full p-1"
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                </motion.div>
              ))}
            </div>
          )}
        </div>
      </div>
      {showRemoveModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-gray-800 p-6 rounded-2xl shadow-lg shadow-purple-800">
            <h3 className="text-xl font-bold mb-4">Remove Game</h3>
            <p>Are you sure you want to remove <span className="font-bold">{gameToRemove?.title}</span> from your library?</p>
            <div className="flex space-x-4 mt-4">
              <button
                onClick={() => setShowRemoveModal(false)}
                className="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded-full"
              >
                Cancel
              </button>
              <button
                onClick={handleRemoveGame}
                className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-full"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GameLibrary;