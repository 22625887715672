import React from "react";
import { Link } from "react-router-dom";

const Review = ({ user, text, gameTitle, timestamp }) => {
  return (
    <div className="bg-gray-800/40 p-4 rounded-lg border border-purple-300/30 hover:border-purple-200/50 hover:shadow-sm hover:shadow-purple-200/20 transition-colors duration-300">
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-xl font-semibold font-poppins mr-2 truncate">
          {gameTitle}
        </h2>
      </div>
      <div className="mt-2 bg-gray-500/20 p-3 rounded-lg">
        <div className="flex items-center mb-2">
          <div className="flex-shrink-0 w-8 h-8 bg-purple-600 rounded-full flex items-center justify-center">
            <span className="text-sm font-bold font-poppins">
              {user.charAt(0)}
            </span>
          </div>
          <Link
            to={`/profile/${user}`}
            className="ml-2 text-lg font-semibold font-poppins hover:text-purple-400 hover:underline"
          >
            {user}
          </Link>
          <div className="ml-auto text-gray-400 text-xs font-poppins">
            {timestamp}
          </div>
        </div>
        <p className="text-sm line-clamp-3 font-poppins mt-4">{text}</p>
      </div>
    </div>
  );
};

export default Review;
