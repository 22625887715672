import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { auth, backEndUrl, backApiKey, default_icon } from "../../config";
import { motion } from "framer-motion";
import { StarIcon, ArrowDownOnSquareIcon } from "@heroicons/react/24/solid";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

function GamePage() {
  const { id } = useParams();
  const [gameData, setGameData] = useState(null);
  const [gameHash, setGameHash] = useState(null);
  const [showReviewInput, setShowReviewInput] = useState(false);
  const [newReview, setNewReview] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [developer, setDeveloper] = useState("N/A");
  const [isLoading, setIsLoading] = useState(true);
  const [isGameOwned, setIsGameOwned] = useState(false);
  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const [rating, setRating] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);

  const fetchUserData = async () => {
    if (!currentUser) return;
    console.log("Fetching user data...");
    try {
      const response = await fetch(`${backEndUrl}/users/${currentUser.uid}`, {
        headers: {
          "x-api-key": backApiKey,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        setDeveloper(data.userInfo?.username);
        setIsGameOwned(data?.gameIds.includes(id));
        setUserDataLoaded(true);
      } else {
        console.error("Error fetching user: ", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) setUserDataLoaded(true);
    });

    fetchGameData();
    fetchUserData();

    return () => unsubscribe();
  }, [id]);

  const fetchGameData = async () => {
    try {
      const response = await fetch(`${backEndUrl}/games/${Number(id)}`, {
        headers: {
          "x-api-key": backApiKey,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        setGameHash(data.id);
        setGameData(data);
        setIsGameOwned(
          currentUser &&
            currentUser.uid &&
            data.userInfo &&
            data.userInfo.gameIds.includes(id)
        );

        // Set preview images
        if (data.previewImages && data.previewImages.length > 0) {
          setPreviewImages(data.previewImages);
        }

        setIsLoading(false);
      } else if (response.status === 404) {
        console.log(`Game with ID ${id} not found.`);
      } else {
        console.error("Error fetching games: ", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching games:", error);
    }
  };

  const handleCancelReview = () => {
    setShowReviewInput(false);
    setNewReview("");
  };

  const handleEnterReview = async () => {
    try {
      const reviewDetails = {
        gameId: gameHash,
        userId: currentUser.uid,
        comment: newReview,
        rating: rating,
      };

      const response = await fetch(`${backEndUrl}/reviews/new`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": backApiKey,
        },
        body: JSON.stringify(reviewDetails),
      });

      if (response.ok) {
        handleCancelReview();
        console.log("Review created successfully");
        fetchGameData(); // Refresh game data to show the new review
      } else {
        console.error("Error creating review:", response.statusText);
      }
    } catch (error) {
      console.error("Error creating review:", error.message);
    }
  };

  const handlePurchase = async () => {
    if (currentUser) {
      try {
        const response = await fetch(
          `${backEndUrl}/users/${currentUser.uid}/games`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": backApiKey,
            },
            body: JSON.stringify({ gameId: gameData.id.toString() }),
          }
        );

        if (response.status === 200) {
          console.log("Game purchased successfully!");
          setIsGameOwned(true);
        } else {
          console.error("Failed to purchase the game");
        }
      } catch (error) {
        console.error("Error during purchase:", error);
      }
    }
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleDownloadDemo = async () => {
    if (!gameData || !gameData.gameFilePath) {
      console.error("Game file path is not available");
      return;
    }
    setIsDownloading(true);

    try {
      const response = await fetch(gameData.gameFilePath);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;

      const fileName = gameData.gameFilePath.split("/").pop().split("?")[0];
      a.download = fileName;

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file:", error);
      alert("There was an error downloading the file. Please try again later.");
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-slate-900 to-black text-white p-8 md:p-16 lg:p-32">
      {isLoading || !gameData ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex justify-center items-center h-64"
        >
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"></div>
        </motion.div>
      ) : (
        userDataLoaded && (
          <div className="max-w-7xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-12"
            >
              {/* Left Column: Game Cover and Purchase/Download Buttons */}
              <div className="lg:col-span-1">
                <img
                  className="w-full h-auto rounded-2xl shadow-lg mb-4"
                  src={gameData.coverImage}
                  alt={gameData.title}
                  onError={(event) => {
                    event.target.src = default_icon;
                    event.onerror = null;
                  }}
                />
                <div className="space-y-4">
                  <button
                    className={`w-full py-3 px-4 rounded-full font-bold text-lg ${
                      isGameOwned
                        ? "bg-purple-800 cursor-not-allowed"
                        : "bg-purple-600 hover:bg-purple-700"
                    }`}
                    onClick={handlePurchase}
                    disabled={isGameOwned}
                  >
                    {isGameOwned ? "Added to library" : "Add to library"}
                  </button>
                  <button
                    className={`w-full py-3 px-4 rounded-full font-bold text-lg ${
                      isDownloading
                        ? "bg-gray-600 cursor-not-allowed"
                        : "bg-purple-600 hover:bg-purple-800 shadow-lg hover:shadow-purple-400"
                    } flex items-center justify-center`}
                    onClick={handleDownloadDemo}
                    disabled={isDownloading}
                  >
                    {isDownloading ? (
                      <>
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Downloading...
                      </>
                    ) : (
                      <>
                        <ArrowDownOnSquareIcon className="h-5 w-5 mr-2" />
                        Download Demo
                      </>
                    )}
                  </button>
                </div>
              </div>

              {/* Right Column: Game Details */}
              <div className="lg:col-span-2">
                <h1 className="text-4xl font-bold mb-4">{gameData.title}</h1>
                <p className="text-gray-300 mb-6">{gameData.description}</p>
                <div className="grid grid-cols-2 gap-4 text-sm mb-6">
                  <div>
                    <span className="font-bold">Developer:</span> {developer}
                  </div>
                  <div>
                    <span className="font-bold">Release Date:</span>{" "}
                    {new Date(gameData.releaseDate).toLocaleDateString("en-GB")}
                  </div>
                  <div className="col-span-2">
                    <span className="font-bold">Genres:</span>
                    <div className="flex flex-wrap gap-2 mt-2">
                      {gameData.genres.map((genre, index) => (
                        <span
                          key={index}
                          className="bg-purple-400 px-4 py-3 rounded-full text-xs"
                        >
                          {genre}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                {/* Preview Images Section */}
                {previewImages.length > 0 && (
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                    className="mb-12"
                  >
                    <h2 className="text-2xl font-bold mb-4">Preview Images</h2>
                    <div className="grid grid-cols-4 md:grid-cols-3 lg:grid-cols-3 gap-4">
                      {previewImages.map((image, index) => (
                        <motion.div
                          key={index}
                          whileHover={{ scale: 1.05 }}
                          className="relative aspect-w-24 aspect-h-12 rounded-lg overflow-hidden"
                        >
                          <img
                            src={image}
                            alt={`Preview ${index + 1}`}
                            className="w-full h-full"
                            onError={(e) => {
                              e.target.src = default_icon;
                              e.target.onerror = null;
                            }}
                          />
                        </motion.div>
                      ))}
                    </div>
                  </motion.div>
                )}
              </div>
            </motion.div>

            {/* Reviews Section */}
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <h2 className="text-2xl font-bold mb-4">Reviews</h2>
              {isGameOwned && (
                <button
                  className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full mb-4 shadow-lg hover:shadow-purple-400"
                  onClick={() => setShowReviewInput(!showReviewInput)}
                >
                  {showReviewInput ? "Cancel Review" : "Add a Review"}
                </button>
              )}
              {showReviewInput && (
                <div className="bg-gray-800/20 p-4 rounded-lg mb-4">
                  <textarea
                    className="w-full bg-gray-700/20 text-white rounded-lg p-2 mb-2"
                    rows="4"
                    placeholder="Write your review..."
                    value={newReview}
                    onChange={(e) => setNewReview(e.target.value)}
                  ></textarea>
                  <div className="flex items-center mb-2">
                    <span className="mr-2">Rating:</span>
                    {[1, 2, 3, 4, 5].map((star) => (
                      <StarIcon
                        key={star}
                        className={`h-6 w-6 cursor-pointer ${
                          star <= rating ? "text-yellow-400" : "text-gray-400"
                        }`}
                        onClick={() => handleRatingChange(star)}
                      />
                    ))}
                  </div>
                  <button
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full shadow-lg hover:shadow-purple-400"
                    onClick={handleEnterReview}
                  >
                    Submit Review
                  </button>
                </div>
              )}
              {/* Display existing reviews */}
              <div className="space-y-4">
                {gameData.reviews &&
                  gameData.reviews.map((review, index) => (
                    <div key={index} className="bg-gray-800 p-4 rounded-lg">
                      <div className="flex items-center mb-2">
                        <span className="font-bold mr-2">
                          {review.username}
                        </span>
                        <div className="flex">
                          {[1, 2, 3, 4, 5].map((star) => (
                            <StarIcon
                              key={star}
                              className={`h-5 w-5 ${
                                star <= review.rating
                                  ? "text-yellow-400"
                                  : "text-gray-400"
                              }`}
                            />
                          ))}
                        </div>
                      </div>
                      <p>{review.comment}</p>
                    </div>
                  ))}
              </div>
            </motion.div>
          </div>
        )
      )}
    </div>
  );
}

export default GamePage;
