import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  getDocs,
  updateDoc,
  collection,
} from "firebase/firestore";
import { auth, storage, backEndUrl, backApiKey, db } from "../../config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FaCopy, FaUser, FaCode, FaBook } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const defaultImageUrl =
  "https://firebasestorage.googleapis.com/v0/b/spine-b8a0b.appspot.com/o/profile_images%2Fempty_user.jpg?alt=media&token=c99a8a5c-0853-4f53-b8c5-67d87cc6a315";

const ProfilePage = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editedUsername, setEditedUsername] = useState("");
  const [editedEmail, setEditedEmail] = useState("");
  const [editedAge, setEditedAge] = useState(age);
  const [error, setError] = useState(null);
  const [userProfileImageUrl, setUserProfileImageUrl] =
    useState(defaultImageUrl);
  const [permissions, setPermissions] = useState(0);
  const [sessionUID, setSessionUID] = useState(null);
  const fileInputRef = useRef(null);
  const { username: usernameParam } = useParams();
  const [user, setUser] = useState(auth.currentUser);
  const [isFriend, setIsFriend] = useState(false);
  const [followersCount, setFollowersCount] = useState(0);
  const [followingsCount, setFollowingsCount] = useState(0);
  const [profileType, setProfileType] = useState("gamer");
  const [apiKey, setApiKey] = useState("No API key found");
  const [activeTab, setActiveTab] = useState("profile");
  const [isUserIdVisible, setIsUserIdVisible] = useState(false);
  const [isApiKeyVisible, setIsApiKeyVisible] = useState(false);
  const isCurrentUserProfile = user && sessionUID === user.uid;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        if (!usernameParam && !user) {
          console.error("No user set.");
          return;
        }

        const url = usernameParam ? `name/${usernameParam}` : `${user.uid}`;
        const response = await fetch(`${backEndUrl}/users/${url}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": backApiKey,
          },
        });

        const res = await response.json();
        if (response.status !== 200) {
          console.error("Error fetching user data:", res.error);
          return;
        }

        const userData = res.userInfo;
        setUsername(userData.username || "");
        setEmail(userData.email || "");
        setAge(userData.age || 0);
        setEditedUsername(userData.username || "");
        setEditedEmail(userData.email || "");
        setEditedAge(userData.age || age);
        setUserProfileImageUrl(userData.profilePicture || defaultImageUrl);
        setPermissions(userData.permissions || 0);
        setSessionUID(userData.userId || null);
        setProfileType(userData.permissions === 1 ? "developer" : "gamer");

        if (user && userData.userId !== user.uid) {
          const querySnapshot = await getDocs(collection(db, "USERS"));
          const friendDoc = querySnapshot.docs.find(
            (doc) => doc.data().username === userData.username
          );
          const actualQuerySnapshot = await getDoc(doc(db, "USERS", user.uid));
          const actualUser = actualQuerySnapshot.data();

          if (friendDoc) {
            const friendUid = friendDoc.id;
            setIsFriend(
              actualUser.followings && actualUser.followings.includes(friendUid)
            );
          }
        }
        setFollowersCount(userData.followers ? userData.followers.length : 0);
        setFollowingsCount(
          userData.followings ? userData.followings.length : 0
        );

        localStorage.setItem("username", userData.username || "");
        localStorage.setItem("email", userData.email || "");
        localStorage.setItem("age", userData.age || 0);
      } catch (error) {
        console.log("Error getting user data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [usernameParam, user]);

  const fetchInitialUserData = async () => {
    if (!sessionUID) return;

    try {
      const apiKeyResponse = await fetch(`${backEndUrl}/key/${sessionUID}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": backApiKey,
        },
      });

      const apiKeyData = await apiKeyResponse.json();
      if (apiKeyResponse.status === 200) {
        if (apiKeyData.message && apiKeyData.message === "No API key found") {
          setApiKey("No API key found");
        } else {
          setApiKey(apiKeyData.apiKey);
        }
      }
    } catch (error) {
      console.error("Error fetching initial user data or API key: ", error);
    }
  };

  useEffect(() => {
    if (sessionUID && isCurrentUserProfile) {
      fetchInitialUserData();
    }
  }, [sessionUID, isCurrentUserProfile]);

  const handleProfileImageChange = async () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const storageRef = ref(
          storage,
          `profile_images/${auth.currentUser.uid}`
        );
        await uploadBytes(storageRef, file);
        const downloadUrl = await getDownloadURL(storageRef);
        setUserProfileImageUrl(downloadUrl);

        localStorage.setItem("profilePictureUrl", downloadUrl);

        const user = auth.currentUser;
        if (!user) {
          console.error("No user set.");
          return;
        }
        const response = await fetch(`${backEndUrl}/users/${user.uid}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": backApiKey,
          },
          body: JSON.stringify({
            userInfo: {
              profilePicture: downloadUrl,
            },
          }),
        });

        const res = await response.json();

        if (response.status !== 200) {
          console.error("Error updating user data:", res.error);
          return;
        }
      } catch (error) {
        console.error("Error uploading profile image:", error);
      }
    }
  };

  const handleAddFriend = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "USERS"));
      const friendDoc = querySnapshot.docs.find(
        (doc) => doc.data().username === username
      );

      if (friendDoc) {
        const friendUid = friendDoc.id;
        const userDocRef = doc(db, "USERS", user.uid);
        const friendDocRef = doc(db, "USERS", friendUid);

        const userDocSnapshot = await getDoc(userDocRef);
        const friendDocSnapshot = await getDoc(friendDocRef);

        if (userDocSnapshot.exists() && friendDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();

          if (!userData.followings) {
            userData.followings = [];
          }
          const friendIndex = userData.followings.indexOf(friendUid);
          if (friendIndex !== -1) {
            userData.followings.splice(friendIndex, 1);
            await updateDoc(userDocRef, { followings: userData.followings });

            console.log("Friend removed successfully");
            setIsFriend(false);
            const followedUserData = friendDocSnapshot.data();
            if (
              followedUserData.followers &&
              followedUserData.followers.includes(user.uid)
            ) {
              const followerIndex = followedUserData.followers.indexOf(
                user.uid
              );
              followedUserData.followers.splice(followerIndex, 1);
              await updateDoc(friendDocRef, {
                followers: followedUserData.followers,
              });
            }
          } else {
            userData.followings.push(friendUid);
            await updateDoc(userDocRef, { followings: userData.followings });

            setIsFriend(true);
            console.log("Friend added successfully");
            const followedUserData = friendDocSnapshot.data();
            if (!followedUserData.followers) {
              followedUserData.followers = [];
            }
            followedUserData.followers.push(user.uid);
            await updateDoc(friendDocRef, {
              followers: followedUserData.followers,
            });
          }
        } else {
          console.error("User or friend document does not exist");
        }
      } else {
        console.error("Friend document not found");
      }
    } catch (error) {
      console.error("Error adding/removing friend:", error);
    }
  };

  const handleEditProfile = async () => {
    if (!isEditing) {
      setIsEditing(true);
      setEditedUsername(username);
      setEditedEmail(email);
      setEditedAge(age);
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user set.");
        return;
      }
      const response = await fetch(`${backEndUrl}/users/${user.uid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": backApiKey,
        },
        body: JSON.stringify({
          userInfo: {
            username: editedUsername,
            email: editedEmail,
            age: editedAge,
          },
          userAuth: {
            displayName: editedUsername,
            email: editedEmail,
            age: editedAge,
            photoURL: userProfileImageUrl,
          },
        }),
      });

      const res = await response.json();

      if (response.status !== 200) {
        console.error("Error updating user data:", res.error);
        setError(res.error);
        return;
      }

      setUsername(editedUsername);
      setEmail(editedEmail);
      setAge(editedAge);
      localStorage.setItem("username", editedUsername);
      localStorage.setItem("email", editedEmail);
      localStorage.setItem("age", editedAge);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating user data: ", error);
      setError(error.message);
    }
  };

  const handleProfileTypeChange = (event) => {
    setProfileType(event.target.value);
  };

  const updateProfileTypeInDatabase = async () => {
    try {
      const userDocRef = doc(db, "USERS", user.uid);
      const newPermissions = profileType === "gamer" ? 0 : 1;
      await updateDoc(userDocRef, {
        permissions: newPermissions,
      });
      setPermissions(newPermissions);
      console.log("User type updated successfully");
    } catch (error) {
      console.error("Error updating user type:", error);
    }
  };

  const regenerateApiKey = async () => {
    try {
      const apiKeyResponse = await fetch(`${backEndUrl}/key/new`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": backApiKey,
        },
        body: JSON.stringify({
          userId: sessionUID,
        }),
      });

      const apiKeyData = await apiKeyResponse.json();

      if (apiKeyResponse.status === 200) {
        setApiKey(apiKeyData.apiKey);
      }
    } catch (error) {
      console.error("Error fetching initial user data or API key: ", error);
    }
  };

  const deleteApiKey = async () => {
    try {
      const apiKeyResponse = await fetch(`${backEndUrl}/key/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": backApiKey,
        },
        body: JSON.stringify({
          userId: sessionUID,
        }),
      });

      if (apiKeyResponse.status === 200) {
        setApiKey("No API key found");
      }
    } catch (error) {
      console.error("Error fetching initial user data or API key: ", error);
    }
  };

  useEffect(() => {
    if (sessionUID && isCurrentUserProfile) {
      fetchInitialUserData();
    }
  }, [sessionUID, isCurrentUserProfile]);

  const openLinkInNewTab = (websiteUrl) => {
    window.open(websiteUrl, "_blank");
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  const renderProfileContent = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="grid grid-cols-1 md:grid-cols-2 gap-6"
    >
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
        <img
          src={userProfileImageUrl}
          alt="Profile"
          className="w-32 h-32 rounded-full mx-auto mb-4"
        />
        {isCurrentUserProfile && (
          <button
            onClick={handleProfileImageChange}
            className="bg-purple-600 text-white px-4 py-2 rounded-full block mx-auto mb-4 shadow-2xl shadow-purple-300/10 hover:shadow-purple-500"
          >
            Change Profile Picture
          </button>
        )}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileInputChange}
          className="hidden"
        />
        <h2 className="text-2xl font-bold text-center mb-2">{username}</h2>
        <p className="text-gray-400 text-center">{email}</p>
      </div>
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold mb-4">Stats</h3>
        <div className="grid grid-cols-2 gap-4">
          <div className="text-center">
            <p className="text-3xl font-bold">{followersCount}</p>
            <p className="text-gray-400">Followers</p>
          </div>
          <div className="text-center">
            <p className="text-3xl font-bold">{followingsCount}</p>
            <p className="text-gray-400">Following</p>
          </div>
        </div>
        {isCurrentUserProfile ? (
          <button
            onClick={handleEditProfile}
            className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-full mt-4 block w-full shadow-2xl shadow-purple-300/10 hover:shadow-purple-500"
          >
            {isEditing ? "Save Profile" : "Edit Profile"}
          </button>
        ) : (
          <button
            onClick={handleAddFriend}
            className={`${
              isFriend ? "bg-red-500" : "bg-purple-600"
            } text-white px-4 py-2 rounded-full mt-4 block w-full`}
          >
            {isFriend ? "Unfollow" : "Follow"}
          </button>
        )}
      </div>
      {isEditing && isCurrentUserProfile && (
        <div className="col-span-2 bg-gray-800 p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-bold mb-4">Edit Profile</h3>
          <input
            type="text"
            value={editedUsername}
            onChange={(e) => setEditedUsername(e.target.value)}
            className="bg-gray-700 text-white px-4 py-2 rounded-2xl mb-4 w-full"
            placeholder="Username"
          />
          <input
            type="email"
            value={editedEmail}
            onChange={(e) => setEditedEmail(e.target.value)}
            className="bg-gray-700 text-white px-4 py-2 rounded-2xl mb-4 w-full"
            placeholder="Email"
          />
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-400 mb-2">
              Profile Type
            </label>
            <select
              value={profileType}
              onChange={handleProfileTypeChange}
              className="bg-gray-700 text-white px-4 py-2 rounded-2xl w-full"
            >
              <option
                className="bg-purple-800 hover:bg-purple-900 rounded-2xl"
                value="gamer"
              >
                Gamer
              </option>
              <option
                className="bg-purple-800 hover:bg-purple-900 rounded-2xl"
                value="developer"
              >
                Developer
              </option>
            </select>
          </div>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <button
            onClick={() => {
              handleEditProfile();
              updateProfileTypeInDatabase();
            }}
            className="bg-purple-600 text-white px-4 py-2 rounded-full block w-full"
          >
            Save Changes
          </button>
        </div>
      )}
    </motion.div>
  );

  const renderDeveloperContent = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="bg-gray-800 p-6 rounded-lg shadow-lg"
    >
      <h3 className="text-xl font-bold mb-4">Developer Information</h3>
      <div className="mb-4">
        <button
          onClick={() => navigate("/panel")}
          className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-full"
        >
          My games
        </button>
      </div>
      <div className="mb-4">
        <p className="font-bold mb-2">User ID</p>
        <div className="flex items-center bg-gray-700 rounded-2xl p-2">
          <span className="flex-grow">
            {isUserIdVisible ? sessionUID : "••••••••••••••••"}
          </span>
          <button
            onClick={() => setIsUserIdVisible(!isUserIdVisible)}
            className="ml-2 font-bold text-purple-400 hover:text-purple-500"
          >
            {isUserIdVisible ? "Hide" : "Show"}
          </button>
          <button
            onClick={() => copyToClipboard(sessionUID)}
            className="ml-4 w-6 h-6 text-purple-400 hover:text-purple-500"
          >
            <FaCopy />
          </button>
        </div>
      </div>
      <div className="mb-4">
        <p className="font-bold mb-2">API Key</p>
        <div className="flex items-center bg-gray-700 rounded-2xl p-2">
          <span className="flex-grow">
            {isApiKeyVisible ? apiKey : "••••••••••••••••"}
          </span>
          <button
            onClick={() => setIsApiKeyVisible(!isApiKeyVisible)}
            className="ml-2 font-bold text-purple-400 hover:text-purple-500"
          >
            {isApiKeyVisible ? "Hide" : "Show"}
          </button>
          <button
            onClick={() => copyToClipboard(apiKey)}
            className="ml-4 w-6 h-6 text-purple-400 hover:text-purple-500"
          >
            <FaCopy />
          </button>
        </div>
      </div>
      <div className="flex space-x-4">
        <button
          onClick={regenerateApiKey}
          className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-full"
        >
          Regenerate API Key
        </button>
        <button
          onClick={deleteApiKey}
          className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-full"
        >
          Delete API Key
        </button>
      </div>
    </motion.div>
  );

  const renderDocumentationContent = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="bg-gray-800 p-6 rounded-lg shadow-lg"
    >
      <h3 className="text-xl font-bold mb-4">Documentation</h3>
      <div className="flex flex-wrap justify-center gap-2 mb-6">
        <button
          className="bg-purple-400 py-2 px-4 rounded-xl border-purple-500 hover:bg-purple-500 shadow-2xl transition hover:border-purple-500 hover:shadow-purple-500"
          onClick={() =>
            openLinkInNewTab("https://github.com/Shorssaud/ExampleGamesSpine")
          }
        >
          Github
        </button>
        <button
          className="bg-purple-400 py-2 px-4 rounded-xl border-purple-500 hover:bg-purple-500 shadow-2xl transition hover:border-purple-500 hover:shadow-purple-500"
          onClick={() =>
            openLinkInNewTab(
              "https://github.com/Shorssaud/ExampleGamesSpine/releases"
            )
          }
        >
          Plugins
        </button>
        <button
          className="bg-purple-400 py-2 px-4 rounded-xl border-purple-500 hover:bg-purple-500 shadow-2xl transition hover:border-purple-500 hover:shadow-purple-500"
          onClick={() =>
            openLinkInNewTab(
              "https://github.com/Shorssaud/ExampleGamesSpine/blob/main/Unity%20Package/Spine%20Analytics/Quickstart.md"
            )
          }
        >
          Unity
        </button>
        <button
          className="bg-purple-400 py-2 px-4 rounded-xl border-purple-500 hover:bg-purple-500 shadow-2xl transition hover:border-purple-500 hover:shadow-purple-500"
          onClick={() =>
            openLinkInNewTab(
              "https://github.com/Shorssaud/ExampleGamesSpine/blob/main/GodotPackage/GodotSpine_QuickStart.md"
            )
          }
        >
          Godot
        </button>
        <button
          className="bg-purple-400 py-2 px-4 rounded-xl border-purple-500 hover:bg-purple-500 shadow-2xl transition hover:border-purple-500 hover:shadow-purple-500"
          onClick={() =>
            openLinkInNewTab(
              "https://github.com/Shorssaud/ExampleGamesSpine/blob/main/UnrealGame/Documentation/UnrealQuickStart.md"
            )
          }
        >
          Unreal
        </button>
      </div>
    </motion.div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-slate-900 to-black text-white p-28">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row gap-8">
          {isCurrentUserProfile && (
            <div className="md:w-1/4">
              <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-4">Menu</h2>
                <ul className="space-y-2">
                  <li>
                    <button
                      onClick={() => setActiveTab("profile")}
                      className={`flex items-center w-full p-2 rounded-lg ${
                        activeTab === "profile"
                          ? "bg-purple-600"
                          : "hover:bg-gray-700"
                      }`}
                    >
                      <FaUser className="mr-2" /> Profile
                    </button>
                  </li>
                  {permissions === 1 && (
                    <>
                      <li>
                        <button
                          onClick={() => setActiveTab("developer")}
                          className={`flex items-center w-full p-2 rounded-lg ${
                            activeTab === "developer"
                              ? "bg-purple-600"
                              : "hover:bg-gray-700"
                          }`}
                        >
                          <FaCode className="mr-2" /> Developer
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => setActiveTab("documentation")}
                          className={`flex items-center w-full p-2 rounded-lg ${
                            activeTab === "documentation"
                              ? "bg-purple-600"
                              : "hover:bg-gray-700"
                          }`}
                        >
                          <FaBook className="mr-2" /> Documentation
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          )}
          <div className={isCurrentUserProfile ? "md:w-3/4" : "w-full"}>
            <AnimatePresence mode="wait">
              {activeTab === "profile" && renderProfileContent()}
              {activeTab === "developer" &&
                isCurrentUserProfile &&
                permissions === 1 &&
                renderDeveloperContent()}
              {activeTab === "documentation" &&
                isCurrentUserProfile &&
                permissions === 1 &&
                renderDocumentationContent()}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
