import React from "react";
import { motion } from "framer-motion";

const ProductShowcase = () => {
  return (
    <section className="bg-black text-white py-20 px-4 min-h-screen flex items-center">
      <div className="max-w-6xl mx-auto">
        <motion.h2
          className="text-7xl font-poppins font-bold text-center mb-20 bg-clip-text text-transparent bg-gradient-to-b from-purple-200 to-purple-200/10"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.9 }}
        >
          Welcome to SPINE
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <motion.div
            className="backdrop-blur-sm bg-gradient-to-br font-poppins from-black via-purple-500 to-black rounded-2xl p-8 shadow-2xl transition hover:border-purple-100/50 hover:shadow-purple-600/50"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.9, delay: 0.4 }}
          >
            <h3 className="text-3xl font-semibold mb-4">
              For Indie Developers
            </h3>
            <p className="text-lg mb-6">
              Showcase your game demos to a global audience. Get the exposure
              you deserve.
            </p>
          </motion.div>

          <motion.div
            className="font-poppins bg-gradient-to-br from-black via-indigo-800 to-black rounded-2xl p-8 shadow-2xl transition hover:border-blue-100/50 hover:shadow-blue-600/50"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.9, delay: 0.4 }}
          >
            <h3 className="text-3xl font-semibold mb-4">For Gamers</h3>
            <p className="text-lg mb-6">
              Play exciting indie game demos and connect with new people on our
              platform.
            </p>
          </motion.div>
        </div>
        <motion.div
          className="relative font-poppins rounded-3xl overflow-hidden shadow-2xl transition hover:border-purple-100/50 hover:shadow-purple-600/50"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.9, delay: 0.4 }}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-black via-purple-900 to-black opacity-75 "></div>

          <div className="relative z-10 p-12 text-center">
            <h3 className="text-5xl font-bold mb-6 ">
              Are you an indie game developer?
            </h3>
            <p className="text-xl mb-8 leading-relaxed max-w-3xl mx-auto">
              SPINE is revolutionizing the way indie games reach their audience.
              <p>
                Showcase your creativity, connect with players, and turn your
                passion into success.
              </p>
            </p>
            <motion.button
              className="bg-white text-purple-900 font-bold text-lg py-4 px-8 rounded-full transition duration-300 shadow-lg"
              whileHover={{
                scale: 1.05,
                boxShadow: "0 0 25px rgba(167, 139, 250, 0.5)",
              }}
              whileTap={{ scale: 0.95 }}
              onClick={() => (window.location.href = "/login")}
            >
              Join the Future of Indie Gaming
            </motion.button>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default ProductShowcase;
