import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, db, backEndUrl, backApiKey } from "../../config";
import { motion } from "framer-motion";
import {
  SparklesIcon,
  MagnifyingGlassIcon,
  Bars3BottomRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import {
  doc,
  collection,
  getDoc,
  query,
  getDocs,
  limit,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navItems = ["Browse", "Library", "Community", "Profile"];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        const docRef = doc(db, "USERS", user.uid);
        const docSnap = await getDoc(docRef);
        setIsAdmin(docSnap.data()?.admin === true);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSearchChange = async (e) => {
    const newSearchTerm = e.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    if (newSearchTerm.length > 0) {
      const gamesRef = collection(db, "GAMES1");
      const querySnapshot = await getDocs(query(gamesRef, limit(5)));
      const results = querySnapshot.docs
        .map((doc) => doc.data())
        .filter((game) => game.title.toLowerCase().includes(newSearchTerm));
      setSearchResults(results);
      setIsSearchActive(results.length > 0);
    } else {
      setSearchResults([]);
      setIsSearchActive(false);
    }
  };

  const handleSearchClick = (result) => {
    navigate(`/game/${result.id}`);
    setIsSearchActive(false);
    setSearchTerm("");
    setSearchResults([]);
  };

  const handleRandomGame = async () => {
    if (!currentUser) return;
    try {
      const randomized_int = Math.floor((Math.random() * 10) + 1)
      // const response = await fetch(`${backEndUrl}/gameRandom/${currentUser.uid}`, {
      //   headers: { 'x-api-key': backApiKey },
      // });
      // const data = await response.json();
      // navigate(`/game/${data.id}`);
      navigate(`/game/${randomized_int}`);
    } catch (error) {
      console.log(`Error finding a random game: ${error}`);
    }
  };

  const logout = () => {
    localStorage.removeItem("auth-token");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("profilePictureUrl");
    navigate("/");
  };

  return (
    <nav className="bg-gray-950 text-white py-2 px-4 fixed w-full top-0 z-50">
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        <motion.div
          className="text-2xl font-bold cursor-pointer"
          onClick={() => navigate("/browse")}
        >
          <img src="Logos/navbar_logo.png" alt="logo" className="h-20" />
        </motion.div>
        <div className="">
          <div className="relative">
            <input
                type="text"
                placeholder="Search games"
                value={searchTerm}
                onChange={handleSearchChange}
                className="bg-gray-900 text-white rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-purple-600 w-48 lg:w-96"
              />
              <MagnifyingGlassIcon className="h-5 w-5 absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              {isSearchActive && (
                <ul className="absolute z-10 bg-gray-900 w-full mt-2 rounded-2xl shadow-lg">
                  {searchResults.map((result) => (
                    <li
                      key={result.id}
                      onClick={() => handleSearchClick(result)}
                      className="px-4 py-2 hover:bg-purple-800 cursor-pointer rounded-full"
                    >
                      {result.title}
                    </li>
                  ))}
                </ul>
              )}
          </div>
        </div>
        <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleRandomGame}
            className="bg-purple-500 hover:bg-purple-600 rounded-full p-3 shadow-lg hover:shadow-purple-400 transition-colors"
          >
            <SparklesIcon className="h-5 w-5" />
          </motion.button>

        <div className="hidden md:flex items-center space-x-4 lg:space-x-10">
          {navItems.map((item) => (
            <motion.button
              key={item}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`text-md font-bold font-roboto transition-colors ${
                location.pathname === `/${item.toLowerCase()}`
                  ? "text-purple-400 underline-offset-8 underline"
                  : "hover:text-purple-300"
              }`}
              onClick={() => navigate(`/${item.toLowerCase()}`)}
            >
              {item}
            </motion.button>
          ))}

          {isAdmin && (
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="text-md font-bold font-roboto hover:text-purple-300 transition-colors"
              onClick={() => window.open("http://localhost:8082", "_blank")}
            >
              Panel
            </motion.button>
          )}

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="text-md font-bold font-roboto hover:text-red-400 transition-colors"
            onClick={logout}
          >
            Logout
          </motion.button>
        </div>

        <div className="md:hidden">
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? (
              <XMarkIcon className="h-6 w-6" />
            ) : (
              <Bars3BottomRightIcon className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden mt-2">
          <div className="flex flex-col space-y-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search games"
                value={searchTerm}
                onChange={handleSearchChange}
                className="bg-gray-900 text-white rounded-full py-2 px-4 pr-10 focus:outline-none focus:ring-2 focus:ring-purple-600 w-full"
              />
              <MagnifyingGlassIcon className="h-5 w-5 absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              {isSearchActive && (
                <ul className="absolute z-10 bg-gray-900 w-full mt-2 rounded-md shadow-lg">
                  {searchResults.map((result) => (
                    <li
                      key={result.id}
                      onClick={() => handleSearchClick(result)}
                      className="px-4 py-2 hover:bg-gray-800 cursor-pointer"
                    >
                      {result.title}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleRandomGame}
              className="bg-purple-500 hover:bg-purple-600 rounded-full p-3 shadow-lg hover:shadow-purple-400 transition-colors self-start"
            >
              <SparklesIcon className="h-5 w-5" />
            </motion.button>

            {navItems.map((item) => (
              <motion.button
                key={item}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`text-md font-bold font-roboto transition-colors ${
                  location.pathname === `/${item.toLowerCase()}`
                    ? "text-purple-400 underline-offset-8 underline"
                    : "hover:text-purple-300"
                }`}
                onClick={() => {
                  navigate(`/${item.toLowerCase()}`);
                  setIsMenuOpen(false);
                }}
              >
                {item}
              </motion.button>
            ))}

            {isAdmin && (
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="text-md font-bold font-roboto hover:text-purple-300 transition-colors"
                onClick={() => window.open("http://localhost:8082", "_blank")}
              >
                Panel
              </motion.button>
            )}

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="text-md font-bold font-roboto hover:text-red-400 transition-colors"
              onClick={logout}
            >
              Logout
            </motion.button>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
