import React from "react";
import { motion } from "framer-motion";

const TeamMember = ({ name, role, image, linkedin }) => {
  return (
    <motion.div
      className="relative overflow-hidden rounded-2xl group shadow-2xl transition hover:border-purple-100/50 hover:shadow-purple-400/50"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.05 }}
    >
      <div className="relative">
        <img
          src={image}
          alt={name}
          className="object-cover object-center w-full h-80"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-purple-900 opacity-70" />
        <motion.div
          className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300"
          initial={{ scale: 0.8 }}
          whileHover={{ scale: 1 }}
        >
          <a
            href={linkedin}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white p-3 rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 text-purple-600"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
          </a>
        </motion.div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
        <h3 className="text-2xl font-bold mb-1">{name}</h3>
        <p className="text-purple-300 text-sm">{role}</p>
      </div>
    </motion.div>
  );
};

export default TeamMember;
