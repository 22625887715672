import React, { useState, useEffect } from "react";
import { auth, backEndUrl, backApiKey, default_icon } from "../../config";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const PanelPage = () => {
  const navigate = useNavigate();
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserGames = async (uid) => {
      console.log("Fetching user games...");
      try {
        const response = await fetch(`${backEndUrl}/users/${uid}`, {
          headers: {
            "x-api-key": backApiKey,
          },
        });

        if (response.status === 200) {
          const data = await response.json();
          const gameIds = data.userInfo?.gamesPublished;

          if (gameIds) {
            const gamesPublishedMapped = gameIds.map(async (gameId) => {
              const gameResponse = await fetch(
                `${backEndUrl}/games/${gameId}`,
                {
                  headers: {
                    "x-api-key": backApiKey,
                  },
                }
              );

              if (gameResponse.status === 200) {
                return await gameResponse.json();
              } else {
                console.error(
                  "Error fetching game details: ",
                  gameResponse.statusText
                );
                return null;
              }
            });

            const gamesData = await Promise.all(gamesPublishedMapped);
            setGames(gamesData.filter((gameData) => gameData !== null));
          }
        }
      } catch (error) {
        console.error("Error fetching user games:", error);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserGames(user.uid);
      } else {
        console.error("User not authenticated");
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleGameClick = (id) => {
    navigate(`${id}`);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-purple-900 text-white p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold mb-8 mt-20 text-center">
          Published Games
        </h1>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
          </div>
        ) : games.length === 0 ? (
          <div className="text-center">
            <p className="mb-4">No games found.</p>
            <button
              className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
              onClick={() => navigate("publish")}
            >
              Publish new game
            </button>
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              {games.map((game) => (
                <motion.div
                  key={game.id}
                  className="bg-gray-800 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
                  onClick={() => handleGameClick(game.id)}
                  whileHover={{ y: -5 }}
                >
                  <img
                    src={game.gameIcon}
                    alt={game.title}
                    onError={(event) => {
                      event.target.src = default_icon;
                      event.onerror = null;
                    }}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-4">
                    <h3 className="text-xl font-semibold">{game.title}</h3>
                  </div>
                </motion.div>
              ))}
            </div>
            <div className="text-center">
              <button
                className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
                onClick={() => navigate("publish")}
              >
                Publish new game
              </button>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default PanelPage;
