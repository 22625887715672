/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, backEndUrl, backApiKey } from "../../../config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  GithubAuthProvider,
} from "firebase/auth";

function SignUp() {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  var navigate = useNavigate();

  const handleGoogleAuth = () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    provider.addScope("email");
    signInWithPopup(auth, provider)
      .then((data) => {
        const user = data.user;
        const userEmail = user.email;
        localStorage.setItem("email", userEmail);
        addDataToDatabase(user, userEmail);
        navigate("/browse");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGithubAuth = () => {
    const provider = new GithubAuthProvider();
    provider.addScope("user:email");
    signInWithPopup(auth, provider)
      .then((data) => {
        const user = data.user;
        const userEmail = user.email;
        localStorage.setItem("email", userEmail);
        addDataToDatabase(user, userEmail);
        navigate("/browse");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function addDataToDatabase(user, userEmail) {
    const emailToStore = userEmail || user.email;
    try {
      const response = await fetch(`${backEndUrl}/registerOauth`, {
        method: "POST",
        headers: {
          "x-api-key": backApiKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: user.displayName,
          email: emailToStore,
          userId: user.uid,
        }),
      });

      if (response.status !== 201) {
        console.error("Error adding user data: ", response.error);
        return response.error;
      }

      const authToken = response.token;
      localStorage.setItem("auth-token", authToken);
    } catch (error) {
      console.error("Error adding user data: ", error);
      setErrorMessage(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }
    if (password.length < 8) {
      setErrorMessage("Password must be at least 8 characters long");
      return;
    }
    try {
      const reponse = await fetch(`${backEndUrl}/register`, {
        method: "POST",
        headers: {
          "x-api-key": backApiKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          email: email,
          password: password,
        }),
      });
      const data = await reponse.json();

      if (reponse.status !== 201) {
        console.error("Error creating new user jnds: ", data.error);
        setErrorMessage(data.error);
        return;
      }
      console.log("User created with ID:", data.user.uid);

      localStorage.setItem("auth-token", data.token);
      navigate("/browse");
    } catch (error) {
      setErrorMessage(error);
    }
  };

  return (
    <div className="flex h-screen items-center justify-center bg-black">
      <div className="flex max-w-4xl w-full bg-white rounded-lg shadow-2xl shadow-purple-500">
        <div className="hidden md:block w-1/2">
          <img
            src="Auth/signup_image.jpg"
            alt="Sign Up Image"
            className="object-cover h-full w-full rounded-l-lg"
          />
        </div>

        <div className="w-full md:w-1/2 p-8 flex flex-col items-center">
          <img
            src="Logos/black_logo_transparent_bg.PNG"
            alt="Logo"
            className="w-40 h-20"
          />
          <h1 className="text-3xl font-bold text-primary mb-2 text-center">
            Welcome!
          </h1>
          <h1 className="text-sm mb-10 text-center text-gray-500">
            Please enter your details
          </h1>
          {errorMessage && (
            <p className="text-red-500 font-bold text-sm mb-4">{errorMessage}</p>
          )}
          <form onSubmit={handleSubmit} className="w-full">
            <div className="mb-4">
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="w-full px-4 py-2 rounded-lg shadow-md focus:outline-none focus:shadow-outline"
                id="email"
                placeholder="Enter your email"
              />
            </div>
            <div className="mb-4">
              <input
                onChange={(e) => setUsername(e.target.value)}
                type="text"
                className="w-full px-4 py-2 rounded-lg shadow-md focus:outline-none focus:shadow-outline"
                id="username"
                placeholder="Enter your username"
              />
            </div>
            <div className="mb-4">
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                className="w-full px-4 py-2 rounded-lg shadow-md focus:outline-none focus:shadow-outline"
                id="password"
                placeholder="Enter your password"
              />
            </div>
            <div className="mb-4">
              <input
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                className="w-full px-4 py-2 rounded-lg shadow-md focus:outline-none focus:shadow-outline"
                id="confirmPassword"
                placeholder="Confirm your password"
              />
            </div>
            <div className="flex justify-center items-center mb-6 bg-gray-900 rounded-full shadow-2xl hover:shadow-purple-800 transition transform hover:-translate-y-0.5">
              <button
                type="submit"
                className="bg-primary text-white px-6 py-2 rounded-full focus:outline-none hover:bg-primary-dark"
              >
                Sign up
              </button>
            </div>
          </form>
          <div className="flex items-center my-4 w-full">
            <div className="flex-1 border-t border-gray-300"></div>
            <p className="mx-4 text-sm">Or sign up with</p>
            <div className="flex-1 border-t border-gray-300"></div>
          </div>
          <div className="flex items-center justify-center space-x-4 mt-3">
            <button
              onClick={handleGoogleAuth}
              className="flex items-center py-2 px-4 text-base rounded-full bg-white hover:bg-gray-100 text-gray-500 border border-transparent hover:border-transparent hover:text-gray-700 hover:shadow-2xl font-medium shadow-2xl hover:shadow-purple-800 transition transform hover:-translate-y-0.5"
            >
              <svg
                className="px-2"
                xmlns="http://www.w3.org/2000/svg"
                xwidth="20"
                height="20"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="black"
                  d="M21.456 10.154c.123.659.19 1.348.19 2.067c0 5.624-3.764 9.623-9.449 9.623A9.841 9.841 0 0 1 2.353 12a9.841 9.841 0 0 1 9.844-9.844c2.658 0 4.879.978 6.583 2.566l-2.775 2.775V7.49c-1.033-.984-2.344-1.489-3.808-1.489c-3.248 0-5.888 2.744-5.888 5.993c0 3.248 2.64 5.998 5.888 5.998c2.947 0 4.953-1.685 5.365-3.999h-5.365v-3.839h9.26Z"
                />
              </svg>
              Google
            </button>
            <button
              onClick={handleGithubAuth}
              className="flex items-center px-4 text-base rounded-full bg-white hover:bg-gray-100 text-gray-500 border border-transparent hover:border-transparent hover:text-gray-700 hover:shadow-2xl font-medium shadow-2xl hover:shadow-purple-800 transition transform hover:-translate-y-0.5"
            >
              <svg
                className="px-2"
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="42"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="black"
                  d="M12 2C6.48 2 2 6.48 2 12c0 4.42 2.88 8.17 6.86 9.49c.5.09.68-.22.68-.48v-1.68c-2.8.61-3.39-1.35-3.39-1.35c-.46-1.17-1.12-1.48-1.12-1.48c-.91-.62.07-.61.07-.61c1.01.07 1.54 1.03 1.54 1.03c.9 1.54 2.36 1.1 2.93.84c.09-.65.35-1.1.63-1.35c-2.22-.25-4.55-1.11-4.55-4.93c0-1.09.39-1.98 1.03-2.68c-.1-.25-.45-1.27.1-2.64c0 0 .84-.27 2.75 1.02c.8-.22 1.65-.33 2.5-.33c.85 0 1.7.11 2.5.33c1.91-1.29 2.75-1.02 2.75-1.02c.55 1.37.2 2.39.1 2.64c.64.7 1.03 1.59 1.03 2.68c0 3.83-2.34 4.68-4.56 4.93c.36.31.68.92.68 1.85v2.74c0 .26.18.57.69.48C19.13 20.16 22 16.41 22 12C22 6.48 17.52 2 12 2Z"
                />
              </svg>
              GitHub
            </button>
          </div>
          <p className="text-sm mt-6 text-center">
            Already have an account?
            <a
              href="/login"
              className="text-primary font-semibold ml-2 hover:underline"
            >
              Sign in
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
