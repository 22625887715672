import React from 'react';
import Navbar from './components/navbar/Navbar';
import { Outlet } from 'react-router';

function WithNav() {
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
};

export default WithNav;