import React, { useState, useEffect } from "react";
import { auth, backEndUrl, backApiKey, default_icon } from "../../config";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import {
  FaStore,
  FaChartBar,
  FaCog,
  FaArrowLeft,
  FaSkull,
} from "react-icons/fa";

const PanelGamePage = () => {
  const navigate = useNavigate();
  const [game, setGame] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchGameDetails = async () => {
      console.log("Fetching game details...");
      try {
        const response = await fetch(`${backEndUrl}/games/${id}`, {
          headers: {
            "x-api-key": backApiKey,
          },
        });

        if (response.status === 200) {
          const gameData = await response.json();
          console.log(gameData);
          setGame(gameData);
        } else {
          console.error("Error fetching game details: ", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching game details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGameDetails();
  }, [id]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this game?")) {
      fetch(`${backEndUrl}/games/${id}`, {
        method: "DELETE",
        headers: {
          "x-api-key": backApiKey,
        },
      })
        .then((response) => {
          if (response.ok) {
            console.log(response.data);
            navigate("/panel");
          } else {
            console.error("Error deleting game details: ", response.statusText);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-purple-900 text-white p-8">
      <div className="max-w-4xl mx-auto">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
          </div>
        ) : game ? (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-4xl font-bold mb-8 mt-20 text-center">
              {game.title}
            </h1>
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => handleNavigation("/panel")}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mb-4 rounded-lg transition duration-300 ease-in-out flex items-center justify-center"
              >
                <span className="mr-2">
                  <FaArrowLeft />
                </span>
                Back
              </motion.button>
            <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden mb-8">
              <div className="md:flex">
                <div className="md:flex-shrink-0">
                  <img
                    src={game.coverImage}
                    alt={game.title}
                    onError={(event) => {
                      event.target.src = default_icon;
                      event.onerror = null;
                    }}
                    className="h-60 w-full object-cover md:w-48"
                  />
                </div>
                <div className="p-8">
                  <p className="mt-2 text-gray-300 font-bold text-lg">{game.description}</p>
                  <div className="mt-4">
                    <span className="text-gray-300 font-bold text-lg">Genres:</span>{" "}
                    {game.genres ? game.genres.join(", ") : "N/A"}
                  </div>
                  <div className="mt-2">
                    <span className="text-gray-300 font-bold text-lg">Platforms:</span>{" "}
                    {game.platforms ? game.platforms.join(", ") : "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
              <Button
                onClick={() => handleNavigation(`/game/${id}`)}
                icon={<FaStore />}
              >
                Store Page
              </Button>
              <Button
                onClick={() => handleNavigation(`/panel/analytics/${id}`)}
                icon={<FaChartBar />}
              >
                Analytics
              </Button>
              <Button
                onClick={() => handleNavigation(`/panel/settings/${id}`)}
                icon={<FaCog />}
              >
                Settings
              </Button>
              <Button
                onClick={() => handleDelete()}
                icon={<FaSkull />}
                color="red"
                className="bg-red-500"
              >
                Delete Game
              </Button>
            </div>
          </motion.div>
        ) : (
          <div className="text-center">
            <p className="mb-4">No details found for the specified game.</p>
            <Button
              onClick={() => handleNavigation("/panel")}
              icon={<FaArrowLeft />}
            >
              Back to Published Games
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const Button = ({ children, onClick, icon }) => (
  <motion.button
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out flex items-center justify-center"
    onClick={onClick}
  >
    {icon && <span className="mr-2">{icon}</span>}
    {children}
  </motion.button>
);

export default PanelGamePage;
