import React, { useState, useEffect } from "react";
import { auth, storage, backEndUrl, backApiKey } from "../../config";
import { useNavigate } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion";
import { FaUpload, FaTags, FaDesktop, FaImages, FaTimes } from "react-icons/fa";

const PanelPublishPage = () => {
  const navigate = useNavigate();
  const availableGenres = [
    "Action",
    "Adventure",
    "Role-Playing",
    "Strategy",
    "Sports",
  ];
  const availablePlatforms = ["PC", "Xbox", "PS5", "Nintendo Switch", "Mobile"];

  const [titleInput, setTitleInput] = useState("");
  const [descriptionInput, setDescriptionInput] = useState("");
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({
    coverImage: null,
    gameIcon: null,
    gameFile: null,
  });
  const [previewImages, setPreviewImages] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isPublishEnabled, setIsPublishEnabled] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setIsPublishEnabled(
      titleInput.trim() !== "" &&
        descriptionInput.trim() !== "" &&
        selectedGenres.length > 0 &&
        selectedPlatforms.length > 0 &&
        selectedFiles.coverImage !== null &&
        selectedFiles.gameIcon !== null &&
        selectedFiles.gameFile !== null &&
        previewImages.length > 0
    );
  }, [
    titleInput,
    descriptionInput,
    selectedGenres,
    selectedPlatforms,
    selectedFiles,
    previewImages,
  ]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleToggle = (category, value) => {
    if (category === "genres") {
      setSelectedGenres((prev) =>
        prev.includes(value)
          ? prev.filter((genre) => genre !== value)
          : [...prev, value]
      );
    } else if (category === "platforms") {
      setSelectedPlatforms((prev) =>
        prev.includes(value)
          ? prev.filter((platform) => platform !== value)
          : [...prev, value]
      );
    }
  };

  const validateFileType = (file, allowedTypes) => {
    const fileType = file.type.split("/")[1];
    return allowedTypes.includes(fileType);
  };

  const handleFileChange = (file, fileKey) => {
    const allowedTypes = {
      coverImage: ["png", "jpeg", "jpg"],
      gameIcon: ["png", "jpeg", "jpg"],
      gameFile: ["exe", "zip", "rar"],
    };

    if (validateFileType(file, allowedTypes[fileKey])) {
      setSelectedFiles((prev) => ({ ...prev, [fileKey]: file }));
      setErrors((prev) => ({ ...prev, [fileKey]: null }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [fileKey]: `Invalid file type. Allowed types: ${allowedTypes[
          fileKey
        ].join(", ")}`,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (titleInput.trim() === "") newErrors.title = "Title is required";
    if (descriptionInput.trim() === "")
      newErrors.description = "Description is required";
    if (selectedGenres.length === 0)
      newErrors.genres = "At least one genre must be selected";
    if (selectedPlatforms.length === 0)
      newErrors.platforms = "At least one platform must be selected";
    if (!selectedFiles.coverImage)
      newErrors.coverImage = "Cover image is required";
    if (!selectedFiles.gameIcon) newErrors.gameIcon = "Game icon is required";
    if (!selectedFiles.gameFile) newErrors.gameFile = "Game file is required";
    if (previewImages.length === 0)
      newErrors.previewImages = "At least one preview image is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePublish = async () => {
    if (!isPublishEnabled) return;

    try {
      setSubmitting(true);

      const uploadFile = async (file, path) => {
        const filename = generateRandomFilename(file);
        const storageRef = ref(storage, `${path}/${filename}`);
        await uploadBytes(storageRef, file);
        return await getDownloadURL(storageRef);
      };

      const coverImageUrl = await uploadFile(
        selectedFiles.coverImage,
        "games_icon"
      );
      const gameIconUrl = await uploadFile(
        selectedFiles.gameIcon,
        "games_icon"
      );
      const gameFileUrl = await uploadFile(selectedFiles.gameFile, "games");

      const previewImageUrls = await Promise.all(
        previewImages.map((file) => uploadFile(file, "games_preview"))
      );

      const gameDetails = {
        title: titleInput,
        description: descriptionInput,
        genres: selectedGenres,
        platforms: selectedPlatforms,
        publisherUid: currentUser.uid,
        coverImage: coverImageUrl,
        gameIcon: gameIconUrl,
        gameFilePath: gameFileUrl,
        previewImages: previewImageUrls,
      };

      const response = await fetch(`${backEndUrl}/games/new`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": backApiKey,
        },
        body: JSON.stringify(gameDetails),
      });

      if (response.ok) {
        console.log("Game created successfully");
        navigate(`/panel`);
      } else {
        console.error("Error creating game:", response.statusText);
      }
    } catch (error) {
      console.error("Error creating game:", error.message);
      setErrors((prev) => ({
        ...prev,
        submit: "Failed to publish game. Please try again.",
      }));
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => navigate(`/panel`);

  const generateRandomFilename = (file) => {
    const extension = file.name.split(".").pop();
    return `${uuidv4()}.${extension}`;
  };

  const handlePreviewImagesUpload = (event) => {
    const files = Array.from(event.target.files);
    const allowedTypes = ["png", "jpeg", "jpg"];
    const validFiles = files.filter((file) =>
      validateFileType(file, allowedTypes)
    );

    if (validFiles.length !== files.length) {
      setErrors((prev) => ({
        ...prev,
        previewImages:
          "Some files were not added. Only PNG, JPEG, and JPG are allowed.",
      }));
    } else {
      setErrors((prev) => ({ ...prev, previewImages: null }));
    }

    setPreviewImages((prevImages) => [...prevImages, ...validFiles]);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-purple-900 text-white p-8">
      <div className="max-w-3xl mx-auto bg-gray-800 rounded-lg shadow-xl p-8 mt-20">
        <h1 className="text-3xl font-bold mb-6 text-center">
          Publish Your Game
        </h1>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium mb-2" htmlFor="title">
              Game Title
            </label>
            <input
              type="text"
              id="title"
              value={titleInput}
              onChange={(e) => setTitleInput(e.target.value)}
              className="w-full bg-gray-700 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
              placeholder="Enter game title"
            />
            {errors.title && (
              <p className="text-red-500 text-sm mt-1">{errors.title}</p>
            )}
          </div>

          <div>
            <label
              className="block text-sm font-medium mb-2"
              htmlFor="description"
            >
              Game Description
            </label>
            <textarea
              id="description"
              value={descriptionInput}
              onChange={(e) => setDescriptionInput(e.target.value)}
              className="w-full bg-gray-700 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500 h-32"
              placeholder="Describe your game"
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">
              <FaTags className="inline mr-2" />
              Genres
            </label>
            <div className="flex flex-wrap gap-2">
              {availableGenres.map((genre) => (
                <button
                  key={genre}
                  onClick={() => handleToggle("genres", genre)}
                  className={`px-3 py-1 rounded-full text-sm ${
                    selectedGenres.includes(genre)
                      ? "bg-purple-600 text-white"
                      : "bg-gray-700 text-gray-300 hover:bg-gray-600"
                  }`}
                >
                  {genre}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">
              <FaDesktop className="inline mr-2" />
              Platforms
            </label>
            <div className="flex flex-wrap gap-2">
              {availablePlatforms.map((platform) => (
                <button
                  key={platform}
                  onClick={() => handleToggle("platforms", platform)}
                  className={`px-3 py-1 rounded-full text-sm ${
                    selectedPlatforms.includes(platform)
                      ? "bg-purple-600 text-white"
                      : "bg-gray-700 text-gray-300 hover:bg-gray-600"
                  }`}
                >
                  {platform}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">
              <FaUpload className="inline mr-2" />
              Upload Files
            </label>
            <div className="space-y-4">
              {["coverImage", "gameIcon", "gameFile"].map((fileKey) => (
                <div key={fileKey} className="flex items-center space-x-4">
                  <input
                    type="file"
                    onChange={(e) =>
                      handleFileChange(e.target.files[0], fileKey)
                    }
                    className="hidden"
                    id={fileKey}
                  />
                  <label
                    htmlFor={fileKey}
                    className="flex-1 bg-gray-700 text-gray-300 rounded-md px-4 py-2 cursor-pointer hover:bg-gray-600 transition duration-300"
                  >
                    {selectedFiles[fileKey]
                      ? selectedFiles[fileKey].name
                      : `Select ${fileKey}`}
                  </label>
                  {selectedFiles[fileKey] && (
                    <span className="text-green-500">✓</span>
                  )}
                </div>
              ))}
              {errors.coverImage && (
                <p className="text-red-500 text-sm">{errors.coverImage}</p>
              )}
              {errors.gameIcon && (
                <p className="text-red-500 text-sm">{errors.gameIcon}</p>
              )}
              {errors.gameFile && (
                <p className="text-red-500 text-sm">{errors.gameFile}</p>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium mb-2">
              <FaImages className="inline mr-2" />
              Preview Images
            </label>
            <div className="space-y-2">
              <input
                type="file"
                onChange={handlePreviewImagesUpload}
                className="hidden"
                id="previewImages"
                multiple
                accept="image/*"
              />
              <label
                htmlFor="previewImages"
                className="flex items-center justify-center w-full bg-gray-700 text-gray-300 rounded-md px-4 py-2 cursor-pointer hover:bg-gray-600 transition duration-300"
              >
                <FaUpload className="mr-2" />
                Upload Preview Images
              </label>
              {previewImages.length > 0 && (
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                  {previewImages.map((file, index) => (
                    <div key={index} className="relative">
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`Preview ${index + 1}`}
                        className="w-full h-32 object-cover rounded-md"
                      />
                      <button
                        onClick={() =>
                          setPreviewImages((prevImages) =>
                            prevImages.filter((_, i) => i !== index)
                          )
                        }
                        className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 m-1"
                      >
                        <FaTimes />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          {errors.previewImages && (
            <p className="text-red-500 text-sm">{errors.previewImages}</p>
          )}
          {errors.submit && (
            <p className="text-red-500 text-sm mt-4">{errors.submit}</p>
          )}
          <div className="flex justify-end space-x-4 mt-8">
            <button
              onClick={handleCancel}
              className="px-6 py-2 bg-gray-600 rounded-md hover:bg-gray-500 transition duration-300"
            >
              Cancel
            </button>
            <motion.button
              onClick={handlePublish}
              disabled={!isPublishEnabled || submitting}
              className={`px-6 py-2 rounded-md transition duration-300 ${
                isPublishEnabled && !submitting
                  ? "bg-purple-600 hover:bg-purple-500"
                  : "bg-gray-600 cursor-not-allowed"
              }`}
              whileHover={{ scale: isPublishEnabled && !submitting ? 1.05 : 1 }}
              whileTap={{ scale: isPublishEnabled && !submitting ? 0.95 : 1 }}
            >
              {submitting ? "Publishing..." : "Publish Game"}
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelPublishPage;
