import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Review from "../../components/community/Review";
import { backEndUrl, backApiKey, db } from "../../config";
import { motion, AnimatePresence } from "framer-motion";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { collection, query, where, getDocs } from "firebase/firestore";

const CommunityPage = () => {
  const [reviews, setReviews] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  async function queryCollection(url) {
    const response = await fetch(`${backEndUrl}${url}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": backApiKey,
      },
    });

    const data = await response.json();

    if (response.status !== 200) {
      console.error(`Error fetching data at ${url}:`, data.error);
      return null;
    }

    return data;
  }

  useEffect(() => {
    const fetchReviews = async () => {
      setIsLoading(true);
      try {
        const reviewMessages = [];
        const reviews = await queryCollection("/reviews");

        if (!reviews) {
          console.error("Error fetching reviews.");
          setIsLoading(false);
          return;
        }

        for (const review of reviews.reviews) {
          const game = await queryCollection(`/games/${review.gameId}`);
          const user = await queryCollection(`/users/${review.userId}`);

          if (!game || !user) {
            console.warn("Error fetching game or user data.");
            continue;
          }

          const formatDate = (timestamp) => {
            const date = new Date(timestamp);
            const options = {
              month: "long",
              day: "numeric",
              hour: "numeric",
            };
            return date.toLocaleDateString(undefined, options);
          };

          const formattedDate = formatDate(review.timestamp);

          const reviewMessage = {
            id: review.id,
            comment: review.comment,
            timestamp: formattedDate,
            gameTitle: game.title,
            gameIcon: game.game_icon,
            username: user.userInfo.username,
          };

          reviewMessages.push(reviewMessage);
        }

        setReviews(reviewMessages);
        setFilteredReviews(reviewMessages);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const handleSearch = async (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchQuery(searchTerm);

    if (searchTerm.length > 0) {
      const usersRef = collection(db, "USERS");
      const q = query(
        usersRef,
        where("username", ">=", searchTerm),
        where("username", "<=", searchTerm + "\uf8ff")
      );
      const querySnapshot = await getDocs(q);
      const matchedUsers = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(matchedUsers);

      const filtered = reviews.filter((review) =>
        review.username.toLowerCase().includes(searchTerm)
      );
      setFilteredReviews(filtered);
    } else {
      setUsers([]);
      setFilteredReviews(reviews);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-slate-900 to-black text-white p-4 sm:p-6 md:p-8 lg:p-12 xl:p-16">
      <motion.header
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="mb-8 text-center"
      >
        <div className="relative max-w-md mx-auto mt-20">
          <input
            type="text"
            placeholder="Find friends"
            value={searchQuery}
            onChange={handleSearch}
            className="w-full bg-gray-800 text-white rounded-full py-2 px-4 pl-10 focus:outline-none focus:ring-2 focus:ring-purple-600"
          />
          <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
        </div>
        {users.length > 0 && (
          <div className="flex justify-center">
            <div className="mt-2 w-full max-w-md bg-gray-800 rounded-lg shadow-lg">
              {users.map((user) => (
                <div
                  key={user.id}
                  className="px-4 py-2 hover:bg-purple-700 cursor-pointer rounded-lg"
                  onClick={() => navigate(`/profile/${user.username}`)}
                >
                  {user.username}
                </div>
              ))}
            </div>
          </div>
        )}
      </motion.header>

      <AnimatePresence>
        {isLoading ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex justify-center items-center h-64"
          >
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"></div>
          </motion.div>
        ) : filteredReviews.length > 0 ? (
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6"
          >
            {filteredReviews.map((item, index) => (
              <motion.div
                key={item.id}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Review
                  id={item.gameId}
                  user={item.username}
                  rating={item.rating}
                  text={item.comment}
                  gameTitle={item.gameTitle}
                  gameIcon={item.gameIcon}
                  timestamp={item.timestamp}
                />
              </motion.div>
            ))}
          </motion.section>
        ) : (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center text-gray-400 mt-8"
          >
            No reviews found.
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CommunityPage;
