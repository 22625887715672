import React from "react";
import { motion } from "framer-motion";
import { FaDiscord } from "react-icons/fa";

const Contact = () => {
  return (
    <section className="py-24 bg-gradient-to-br from-gray-900 via-gray-900 to-purple-900 ">
      <div className="container mx-auto ">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-300 to-purple-600">
            Join Our Community
          </h2>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
          className="relative w-full max-w-2xl mx-auto"
        >
          <div className="absolute inset-0 bg-purple-600 rounded-3xl blur-lg opacity-30"></div>
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-purple-700 rounded-3xl opacity-40"></div>
          <div className="relative bg-gray-800 bg-opacity-70 backdrop-filter backdrop-blur-xl rounded-3xl p-8 shadow-lg overflow-hidden">
            <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-purple-400 to-purple-900"></div>
            <div className="absolute bottom-0 right-0 w-64 h-64 bg-purple-600 rounded-full filter blur-xl opacity-20 -mr-32 -mb-32"></div>
            <div className="relative z-10 flex flex-col items-center">
              <motion.div 
                whileHover={{ scale: 1.1 }} 
                className="flex justify-center items-center mb-6"
              >
                <FaDiscord className="text-6xl text-purple-400 transform transition-transform duration-300" />
              </motion.div>
              <h3 className="text-3xl font-bold text-white mb-4">Connect with Us on Discord</h3>
              <p className="text-gray-300 mb-8 text-center text-lg">
                Join our vibrant community, discuss game development, and stay updated on the latest news!
              </p>
              <motion.a
                href="https://discord.gg/dNyNN2nXtN"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="inline-block bg-gradient-to-r from-purple-500 via-purple-600 to-purple-800 text-white font-bold py-3 px-8 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50 transition duration-300"
              >
                Join Discord Server
              </motion.a>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;