import React from "react";
import Hero from "../../components/home/HeroSection/Hero";
import About from "../../components/home/About/About";
import Team from "../../components/home/Team/Team";
import Timeline from "../../components/home/Timeline/Timeline";
import Footer from "../../components/home/Footer/Footer";
import Contact from "../../components/home/Contact/Contact";
import Analytics from "../../components/home/Analytics/Analytics";

const HomePage = () => {
  return (
    <div>
      <Hero />
      <About />
      <Analytics />
      <Team />
      <Timeline />
      <Contact />
      <Footer />
    </div>
  );
};

export default HomePage;
