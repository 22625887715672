import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBJx_FA8iTCv2kk_WImfgubQB5uWIo7mHw",
  authDomain: "spine-b8a0b.firebaseapp.com",
  databaseURL:
    "https://spine-b8a0b-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "spine-b8a0b",
  storageBucket: "spine-b8a0b.appspot.com",
  messagingSenderId: "413278686994",
  appId: "1:413278686994:web:cdc51beb5b1a80dc946dcb",
  measurementId: "G-X7C4MFSVPN",
};

// for local testing
// const backEndUrl = "http://127.0.0.1:5001/spine-b8a0b/us-central1/app";

const backEndUrl = "https://app-ekrmeoi24a-uc.a.run.app";

// TODO: find a secure way to keep this key
const backApiKey = "091f3f2b-e660-4225-9ef1-7e969da4a60d";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);

const default_icon =
  "https://firebasestorage.googleapis.com/v0/b/spine-b8a0b.appspot.com/o/white%20background%20black%20logo.PNG?alt=media&token=dd735d1b-84ba-464b-a2ee-f76270c5d680&_gl=1*1gdrjtj*_ga*MTg5NzI2NTE3Ny4xNjc2MjM1OTk5*_ga_CW55HF8NVT*MTY5NjUzMDk1MS45Mi4xLjE2OTY1MzA5NjMuNDguMC4w";

export { app, auth, db, storage, backEndUrl, backApiKey, default_icon };
