import React from "react";
import { Link } from "react-router-dom";
import { default_icon } from "../../config";
import { motion } from "framer-motion";

const GameDetails = ({ game }) => {
  if (!game) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-900 text-white">
        <p className="text-2xl">No game selected</p>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-gray-600/20 text-white p-8 rounded-lg"
    >
      <div className="max-w-6xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="col-span-1 bg-gray-800 rounded-2xl p-6 shadow-lg"
          >
            <img
              src={game.gameIcon}
              className="w-32 h-32 mx-auto rounded-2xl object-cover shadow-md"
              alt={game.title}
              onError={(event) => {
                event.target.src = default_icon;
                event.onerror = null;
              }}
            />
            <h2 className="text-xl font-bold text-center mt-4">{game.title}</h2>
            <div className="mt-4 flex justify-center">
              <Link
                className="bg-purple-700 hover:bg-purple-600 text-white font-bold py-2 px-6 rounded-full"
                to={`/game/${game.id}`}
              >
                Go to Store Page
              </Link>
            </div>
          </motion.div>

          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="col-span-2 bg-gray-800 rounded-2xl p-6 shadow-lg"
          >
            <h3 className="text-2xl font-semibold mb-4">Description</h3>
            <p className="text-gray-300">{game.description}</p>
          </motion.div>

          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="col-span-1 bg-gray-800 rounded-2xl p-6 shadow-lg"
          >
            <h3 className="text-2xl font-semibold mb-4">Genre</h3>
            <p className="text-gray-300">{game.genres || "N/A"}</p>
          </motion.div>

          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="col-span-1 bg-gray-800 rounded-2xl p-6 shadow-lg"
          >
            <h3 className="text-2xl font-semibold mb-4">Release Date</h3>
            <p className="text-gray-300">
              {game.releaseDate
                ? new Date(game.releaseDate).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                  })
                : "N/A"}
            </p>
          </motion.div>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6 }}
            className="col-span-1 bg-gray-800 rounded-2xl p-6 shadow-lg"
          >
            <h3 className="text-2xl font-semibold mb-4">Developer</h3>
            <p className="text-gray-300">{game.developer || "N/A"}</p>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default GameDetails;
