import React from "react";
import { motion } from "framer-motion";
import TeamMember from "./TeamMember";

const Team = () => {
  const teamMembers = [
    {
      name: "Shaun Orssaud",
      role: "Team Leader and Backend Developer",
      image: "Team/shaun.jpeg",
      linkedin: "https://www.linkedin.com/in/shaun-orssaud/",
    },
    {
      name: "Louison Kalifa",
      role: "Backend Developer",
      image: "Team/louison.jpeg",
      linkedin: "https://www.linkedin.com/in/louison-kalifa-404187208/",
    },
    {
      name: "Pol Siles Polo",
      role: "AI Developer",
      image: "Team/pol.jpeg",
      linkedin: "https://www.linkedin.com/in/pol-siles-i-polo-789230194/",
    },
    {
      name: "Léon Ducasse",
      role: "Backend Developer",
      image: "Team/leon.jpeg",
      linkedin: "https://www.linkedin.com/in/leon-ducasse/",
    },
    {
      name: "Angel Amélia Halouane",
      role: "Frontend Developer",
      image: "Team/angel.jpeg",
      linkedin: "https://www.linkedin.com/in/angel-amelia-halouane/",
    },
    {
      name: "Cyprien Piron",
      role: "Frontend Developer",
      image: "Team/cyprien.jpeg",
      linkedin: "https://www.linkedin.com/in/cyprien-piron/",
    },
  ];

  return (
    <section className="py-24 bg-gradient-to-b from-purple-950 via-purple-900 to-purple-950">
      <div className="flex flex-col lg:flex-row container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-5xl font-bold mb-4 mr-10 bg-clip-text text-transparent bg-gradient-to-r from-purple-300 to-purple-600">
            Meet Our Team
          </h2>
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {teamMembers.map((member, index) => (
            <TeamMember key={index} {...member} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
