import React, { useEffect, useState } from "react";
import { backEndUrl, backApiKey } from "../../config";
import { motion } from "framer-motion";
import GameCard from "../../components/browser/gameCard";

const BrowsePage = () => {
  const [games, setGames] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState("All");
  const [ loading, setLoading ] = useState(true);

  async function queryCollection(url) {
    const response = await fetch(`${backEndUrl}${url}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": backApiKey,
      },
    });

    const data = await response.json();

    if (response.status !== 200) {
      console.error(`Error fetching data at ${url}:`, data.error);
      return null;
    }

    return data;
  }

  useEffect(() => {
    const fetchGames = async () => {
      setLoading(true);
      try {
        const gameDatas = [];
        const games = await queryCollection("/games");

        if (!games) {
          console.error("Error fetching games.");
          return;
        }

        for (const game of games) {
          const gameData = {
            id: game.id,
            title: game.title,
            description: game.description,
            genres: game.genres,
            releaseDate: game.releaseDate,
            gameIcon: game.gameIcon,
            coverImage: game.coverImage,
            rating: game.rating,
          };
          gameDatas.push(gameData);
        }
        setGames(gameDatas);
      } catch (error) {
        console.error("Error fetching games:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }
  
  const genres = ["All", ...new Set(games.flatMap((game) => game.genres))];

  const filteredGames =
    selectedGenre === "All"
      ? games
      : games.filter((game) => game.genres.includes(selectedGenre));

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-slate-900 to-black text-white p-4 sm:p-8 md:p-16 lg:p-24 xl:p-32">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="flex justify-center mb-8 mt-24 lg:mt-2 space-x-2 sm:space-x-4 overflow-x-auto"
      >
        {genres.map((genre) => (
          <button
            key={genre}
            onClick={() => setSelectedGenre(genre)}
            className={`px-3 py-1 sm:px-4 sm:py-2 rounded-full text-sm sm:text-sm font-semibold transition-colors whitespace-nowrap ${
              selectedGenre === genre
                ? "bg-purple-600 text-white"
                : "bg-gray-800 text-gray-300 hover:bg-gray-700"
            }`}
          >
            {genre}
          </button>
        ))}
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6 md:gap-8"
      >
        {filteredGames.map((game) => (
          <motion.div
            key={game.id}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <GameCard {...game} />
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default BrowsePage;
