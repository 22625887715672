import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { storage, backEndUrl, backApiKey, default_icon } from "../../config";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion";
import { FaSave, FaTimes, FaUpload, FaTrash } from "react-icons/fa";

const PanelSettingsPage = () => {
  const navigate = useNavigate();
  const [game, setGame] = useState(null);
  const [loading, setLoading] = useState(true);
  const [descriptionInput, setDescriptionInput] = useState("");
  const availableGenres = [
    "Action",
    "Adventure",
    "Role-Playing",
    "Strategy",
    "Sports",
  ];
  const availablePlatforms = ["PC", "Xbox", "PS5", "Nintendo Switch", "Mobile"];
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [newPreviewImages, setNewPreviewImages] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        const response = await fetch(`${backEndUrl}/games/${id}`, {
          headers: { "x-api-key": backApiKey },
        });
        if (response.status === 200) {
          const gameData = await response.json();
          setGame(gameData);
          setSelectedGenres(gameData.genres || []);
          setSelectedPlatforms(gameData.platforms || []);
          setDescriptionInput(gameData.description || "");
          setImagePreview(gameData.coverImage);
          setPreviewImages(gameData.previewImages || []);
        } else {
          console.error("Error fetching game details: ", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching game details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchGameDetails();
  }, [id]);

  const handleToggle = (category, value) => {
    if (category === "genres") {
      setSelectedGenres((prev) =>
        prev.includes(value)
          ? prev.filter((genre) => genre !== value)
          : [...prev, value]
      );
    } else if (category === "platforms") {
      setSelectedPlatforms((prev) =>
        prev.includes(value)
          ? prev.filter((platform) => platform !== value)
          : [...prev, value]
      );
    }
  };

  const handleUpdate = async () => {
    try {
      setSubmitting(true);
      const gameData = {
        ...game,
        description: descriptionInput,
        genres: selectedGenres,
        platforms: selectedPlatforms,
      };

      if (imageFile) {
        const filename = `${uuidv4()}.${imageFile.name.split(".").pop()}`;
        if (game.coverImage) {
          const imageRef = ref(storage, game.coverImage);
          await deleteObject(imageRef);
        }
        const imageStorageRef = ref(storage, `games_icon/${filename}`);
        await uploadBytes(imageStorageRef, imageFile);
        const imageUrl = await getDownloadURL(imageStorageRef);
        gameData.coverImage = imageUrl;
      }

      // Handle preview images
      let updatedPreviewImages = [...previewImages];

      // Upload new preview images
      const newPreviewUrls = await Promise.all(
        newPreviewImages.map(async (file) => {
          const filename = `${uuidv4()}.${file.name.split(".").pop()}`;
          const previewStorageRef = ref(storage, `games_preview/${filename}`);
          await uploadBytes(previewStorageRef, file);
          return await getDownloadURL(previewStorageRef);
        })
      );

      // Combine existing and new preview images
      updatedPreviewImages = [...updatedPreviewImages, ...newPreviewUrls];

      // Update gameData with the new preview images
      gameData.previewImages = updatedPreviewImages;

      const response = await fetch(`${backEndUrl}/games/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": backApiKey,
        },
        body: JSON.stringify(gameData),
      });

      if (response.ok) {
        console.log("Game updated successfully");
        navigate(`/panel/${id}`);
      } else {
        console.error("Error updating game:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating game:", error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handlePreviewImagesSelect = (event) => {
    const files = Array.from(event.target.files);
    setNewPreviewImages((prevImages) => [...prevImages, ...files]);
  };

  const removePreviewImage = (index) => {
    setPreviewImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const removeNewPreviewImage = (index) => {
    setNewPreviewImages((prevImages) =>
      prevImages.filter((_, i) => i !== index)
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  if (!game) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
        <p className="text-2xl mb-4">
          No details found for the specified game.
        </p>
        <button
          onClick={() => navigate("/panel")}
          className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
        >
          Back to Panel
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-purple-900 text-white p-8">
      <div className="max-w-4xl mx-auto bg-gray-800 rounded-lg shadow-xl p-8 mt-20">
        <h1 className="text-3xl font-bold mb-6">Edit Game: {game.title}</h1>

        <div className="mb-6">
          <label className="block text-sm font-medium mb-2">Cover Image</label>
          <div className="flex items-center space-x-4">
            <img
              src={imagePreview || game.coverImage || default_icon}
              alt="Game cover"
              className="w-32 h-32 object-cover rounded-lg"
            />
            <label className="cursor-pointer bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded inline-flex items-center">
              <FaUpload className="mr-2" />
              <span>Upload New Image</span>
              <input
                type="file"
                className="hidden"
                onChange={handleFileSelect}
                accept="image/*"
              />
            </label>
          </div>
        </div>

        <div className="mb-6">
          <label
            className="block text-sm font-medium mb-2"
            htmlFor="description"
          >
            Description
          </label>
          <textarea
            id="description"
            value={descriptionInput}
            onChange={(e) => setDescriptionInput(e.target.value)}
            className="w-full bg-gray-700 text-white rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
            rows="4"
          />
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium mb-2">Genres</label>
          <div className="flex flex-wrap gap-2">
            {availableGenres.map((genre) => (
              <button
                key={genre}
                onClick={() => handleToggle("genres", genre)}
                className={`px-3 py-1 rounded-full text-sm ${
                  selectedGenres.includes(genre)
                    ? "bg-purple-600 text-white"
                    : "bg-gray-700 text-gray-300 hover:bg-gray-600"
                }`}
              >
                {genre}
              </button>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium mb-2">Platforms</label>
          <div className="flex flex-wrap gap-2">
            {availablePlatforms.map((platform) => (
              <button
                key={platform}
                onClick={() => handleToggle("platforms", platform)}
                className={`px-3 py-1 rounded-full text-sm ${
                  selectedPlatforms.includes(platform)
                    ? "bg-purple-600 text-white"
                    : "bg-gray-700 text-gray-300 hover:bg-gray-600"
                }`}
              >
                {platform}
              </button>
            ))}
          </div>
        </div>
        {/* Preview Images Section */}
        <div className="mb-6">
          <label className="block text-sm font-medium mb-2">
            Preview Images
          </label>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-4">
            {previewImages.map((image, index) => (
              <div key={index} className="relative">
                <img
                  src={image}
                  alt={`Preview ${index + 1}`}
                  className="w-full h-32 object-cover rounded-lg"
                />
                <button
                  onClick={() => removePreviewImage(index)}
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 m-1"
                >
                  <FaTrash />
                </button>
              </div>
            ))}
            {newPreviewImages.map((file, index) => (
              <div key={`new-${index}`} className="relative">
                <img
                  src={URL.createObjectURL(file)}
                  alt={`New Preview ${index + 1}`}
                  className="w-full h-32 object-cover rounded-lg"
                />
                <button
                  onClick={() => removeNewPreviewImage(index)}
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 m-1"
                >
                  <FaTrash />
                </button>
              </div>
            ))}
          </div>
          <label className="cursor-pointer bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded inline-flex items-center">
            <FaUpload className="mr-2" />
            <span>Add Preview Images</span>
            <input
              type="file"
              className="hidden"
              onChange={handlePreviewImagesSelect}
              accept="image/*"
              multiple
            />
          </label>
        </div>
        <div className="flex justify-end space-x-4">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate(`/panel/${id}`)}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-3xl inline-flex items-center"
          >
            <FaTimes className="mr-2" />
            Cancel
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleUpdate}
            disabled={submitting}
            className={`bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-3xl inline-flex items-center ${
              submitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <FaSave className="mr-2" />
            {submitting ? "Updating..." : "Update Game"}
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default PanelSettingsPage;
