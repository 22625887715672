import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { backEndUrl, backApiKey, auth } from "../../config";
import { UserGroupIcon, StarIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import Chart from "react-apexcharts";

const PanelAnalyticsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [game, setGame] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ageData, setAgeData] = useState(null);
  const [reviewData, setReviewData] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [sessionUID, setSessionUID] = useState(null);
  const [gameStats, setGameStats] = useState(null);
  const [totalPlayerDeaths, setTotalPlayerDeaths] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error("No user set.");
          return;
        }

        const response = await fetch(`${backEndUrl}/users/${user.uid}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": backApiKey,
          },
        });

        const res = await response.json();
        if (response.status !== 200) {
          console.error("Error fetching user data:", res.error);
          return;
        }

        const userData = res.userInfo;
        setSessionUID(userData.userId || null);

        const apiKeyResponse = await fetch(`${backEndUrl}/key/${userData.userId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": backApiKey,
          },
        });

        const apiKeyData = await apiKeyResponse.json();
        if (apiKeyResponse.status === 200) {
          if (apiKeyData.message && apiKeyData.message === "No API key found") {
            setApiKey("No API key found");
          } else {
            setApiKey(apiKeyData.apiKey);
          }
        }
      } catch (error) {
        console.error("Error fetching user data or API key: ", error);
      } finally {
        setLoading(true);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        const response = await fetch(`${backEndUrl}/games/${id}`, {
          headers: { "x-api-key": backApiKey },
        });
        if (response.status === 200) {
          const gameData = await response.json();
          await fetchAnalyticsData();
          setGame(gameData);
        } else {
          console.error("Error fetching game details: ", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching game details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (apiKey && sessionUID) {
      fetchGameDetails();
      fetchGameStats();
    }
  }, [id, apiKey, sessionUID]);

  useEffect(() => {
    if (gameStats && gameStats.length > 0) {
      let totalDeaths = 0;
      console.log(gameStats)
      gameStats.forEach(stat => {
        const playerDeathStat = stat.statistics.find(s => s.title === "Player Deaths");
        if (playerDeathStat) {
          totalDeaths += parseInt(playerDeathStat.value, 10);
        }
      });
      console.log(totalDeaths)
      setTotalPlayerDeaths(totalDeaths);
    }
  }, [gameStats]);

  const fetchAnalyticsData = async () => {
    try {
      const [ageResponse, reviewResponse] = await Promise.all([
        fetch(`${backEndUrl}/games/${id}/ageDistribution`, {
          headers: { "x-api-key": backApiKey },
        }),
        fetch(`${backEndUrl}/games/${id}/reviewsPercentage`, {
          headers: { "x-api-key": backApiKey },
        }),
      ]);

      if (ageResponse.status === 200) {
        const ageDistributionData = await ageResponse.json();
        setAgeData(aggregateAgeData(ageDistributionData));
      }

      if (reviewResponse.status === 200) {
        const reviewsPercentageData = await reviewResponse.json();
        setReviewData(reviewsPercentageData);
      }
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    }
  };

  const fetchGameStats = async () => {
    try {
      const response = await fetch(`${backEndUrl}/statistics/gameSessions`, {
        headers: {
          "Content-Type": "application/json",
          "dev-api-key": apiKey,
          "user-id": sessionUID,
        },
      });
      if (response.status === 200) {
        const statsData = await response.json();
        const gameStatsData = statsData.filter(stat => stat.gameId === id);
        setGameStats(gameStatsData);
      } else {
        console.error("Error fetching game statistics: ", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching game statistics:", error);
    }
  };

  const aggregateAgeData = (ageData) => {
    const ageRanges = ["13-17", "18-35", "36-50", "51-64", "65+"];
    const aggregatedData = Array(ageRanges.length).fill(0);

    for (const age in ageData) {
      const ageCount = ageData[age];
      const ageRangeIndex = getAgeRangeIndex(+age);
      if (ageRangeIndex !== -1) {
        aggregatedData[ageRangeIndex] += ageCount;
      }
    }

    return {
      labels: ageRanges,
      data: aggregatedData,
    };
  };

  const getAgeRangeIndex = (age) => {
    const ageRanges = [
      { min: 13, max: 17 },
      { min: 18, max: 35 },
      { min: 36, max: 50 },
      { min: 51, max: 64 },
      { min: 65, max: Infinity },
    ];
    return ageRanges.findIndex((range) => age >= range.min && age <= range.max);
  };

  const ageChartOptions = {
    chart: { type: "bar" },
    xaxis: { categories: ageData ? ageData.labels : [] },
    colors: ["#8B5CF6"],
  };

  const reviewChartOptions = {
    chart: { type: "donut" },
    labels: ["Positive", "Negative"],
    colors: ["#8B5CF6", "#EF4444"],
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  if (!game) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
        <p className="text-2xl mb-4">
          No analytics found for the specified game.
        </p>
        <button
          onClick={() => navigate("/panel")}
          className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
        >
          Back to Panel
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-purple-900 text-white p-8">
      <div className="max-w-6xl mx-auto mt-20">
        <h1 className="text-3xl font-bold mb-8">Analytics for {game.title}</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {ageData && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-gray-800 p-6 rounded-lg shadow-lg"
            >
              <h2 className="text-xl font-semibold mb-4 flex items-center">
                <UserGroupIcon className="w-6 h-6 mr-2" />
                Age Distribution
              </h2>
              <Chart
                options={ageChartOptions}
                series={[{ name: "Users", data: ageData.data }]}
                type="bar"
                height={300}
              />
            </motion.div>
          )}

          {reviewData && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-gray-800 p-6 rounded-lg shadow-lg"
            >
              <h2 className="text-xl font-semibold mb-4 flex items-center">
                <StarIcon className="w-6 h-6 mr-2" />
                Review Distribution
              </h2>
              <Chart
                options={reviewChartOptions}
                series={[reviewData.percentage, 100 - reviewData.percentage]}
                type="donut"
                height={300}
              />
            </motion.div>
          )}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-gray-800 p-6 rounded-lg shadow-lg"
          >
            <h2 className="text-xl font-semibold mb-4 flex items-center">
              <XCircleIcon className="w-6 h-6 mr-2" />
              Session Deaths
            </h2>
            <p>Player Deaths: {totalPlayerDeaths}</p>
          </motion.div>
        </div>

        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate(`/panel/${id}`)}
          className="mt-8 bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
        >
          Back to Game Panel
        </motion.button>
      </div>
    </div>
  );
};

export default PanelAnalyticsPage;
