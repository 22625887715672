import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const GameCard = ({
  id,
  title,
  description,
  genres,
  gameIcon,
  coverImage,
  rating,
}) => {
  const stars = Array.from({ length: 5 }, (_, index) => (
    <svg
      key={index}
      className={`h-4 w-4 ${
        index < rating ? "text-purple-600" : "text-purple-200"
      }`}
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M12 .587l3.668 7.568 8.332 1.218-6.006 5.847 1.417 8.273L12 18.897l-7.411 3.895 1.417-8.273-6.006-5.847 8.332-1.218z" />
    </svg>
  ));

  return (
    <motion.div
      className="bg-slate-800 rounded-xl overflow-hidden shadow-lg transition-transform duration-300 ease-in-out hover:shadow-xl hover:shadow-purple-700 "
      whileHover={{ scale: 1.02 }}
    >
      <img
        className="w-full h-48 object-cover"
        src={coverImage}
        alt={`${title} cover`}
      />
      <div className="p-4">
        <div className="flex justify-between items-center mb-2">
          <div className="flex items-center">
            <img
              className="w-10 h-10 rounded-full object-cover border-2 border-purple-500"
              src={gameIcon}
              alt={`${title} icon`}
            />
            <div className="ml-3">
              <h3 className="text-lg font-bold text-white">{title}</h3>
              <p className="text-xs text-gray-400">{genres.join(", ")}</p>
            </div>
          </div>
          <div className="flex items-center pb-2">{stars}</div>
        </div>
        <p className="text-gray-300 text-sm mb-4 h-12 overflow-hidden">
          {description.length > 100
            ? `${description.slice(0, 100)}...`
            : description}
        </p>
        <div className="flex justify-end items-center">
          <Link
            to={`/game/${id}`}
            className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-10 rounded-full transition-colors duration-300 ease-in-out"
          >
            View
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default GameCard;
