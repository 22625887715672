import React, { useEffect, useRef } from "react";

const Hero = () => {
  const canvasRef = useRef(null);
  const scrollIndicatorRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let animationFrameId;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const stars = [];
    const numStars = 200;
    const speed = 0.5;

    for (let i = 0; i < numStars; i++) {
      stars.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 1.5,
        speed: Math.random() * speed + 0.1,
      });
    }

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "rgba(255, 255, 255, 0.8)";

      stars.forEach((star) => {
        ctx.beginPath();
        ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
        ctx.fill();

        star.x -= star.speed;

        if (star.x < 0) {
          star.x = canvas.width;
          star.y = Math.random() * canvas.height;
        }
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  useEffect(() => {
    const scrollIndicator = scrollIndicatorRef.current;
    let progress = 0;
    let direction = 1;

    const animateScrollIndicator = () => {
      progress += 0.02 * direction;
      if (progress > 1 || progress < 0) direction *= -1;

      const y = 20 * Math.sin(progress * Math.PI);
      scrollIndicator.style.transform = `translateY(${y}px)`;

      requestAnimationFrame(animateScrollIndicator);
    };

    animateScrollIndicator();
  }, []);

  return (
    <div className="relative h-screen flex flex-col items-center justify-center overflow-hidden">
      <canvas
        ref={canvasRef}
        className="absolute inset-0 bg-gradient-to-b from-purple-950 via-gray-950 to-black"
      />
      <div className="relative z-10 text-center">
        <h1 className="text-5xl md:text-8xl font-bold mb-4 font-poppins leading-tight bg-clip-text text-transparent bg-gradient-to-b from-purple-300 via-slate-200 to-purple-300">
          SPINE
          <p>has got your back!</p>
        </h1>
        <button
          onClick={() => (window.location.href = "/login")}
          className="cursor-cell mt-8 bg-purple-600 hover:bg-purple-700 text-white font-bold font-poppins py-3 px-6 rounded-full transition shadow-lg shadow-purple-500/50 duration-300 text-lg hover:border-purple-300/50 hover:shadow-purple-300/70"
        >
          Join the Revolution
        </button>
      </div>
      <div
        ref={scrollIndicatorRef}
        className="absolute bottom-8 left-1/2 transform -translate-x-1/2 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="text-purple-300"
        >
          <circle cx="12" cy="12" r="10" />
          <polyline points="8 12 12 16 16 12" />
          <line x1="12" y1="8" x2="12" y2="16" />
        </svg>
      </div>
    </div>
  );
};

export default Hero;
