import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";

import SignIn from "./components/auth/signIn/SignIn.js";
import SignUp from "./components/auth/signUp/SignUp.js";

import HomePage from "./pages/Home/HomePage.js";
import GamePage from "./pages/Game/GamePage.js";
import BrowsePage from "./pages/Browse/BrowsePage";
import LibraryPage from "./pages/Library/LibraryPage";
import CommunityPage from "./pages/Community/CommunityPage";
import ProfilePage from "./pages/Profile/ProfilePage";

import PanelPage from "./pages/Panel/PanelPage";
import PanelPublishPage from "./pages/PanelPublish/PanelPublishPage";
import PanelGamePage from "./pages/PanelGame/PanelGamePage";
import PanelSettingsPage from "./pages/PanelSettings/PanelSettingsPage";
import PanelAnalyticsPage from "./pages/PanelAnalytics/PanelAnalyticsPage.js";

import WithNav from "./WithNav.js";

const UnauthRoute = ({ children }) => {
  const loggedIn = localStorage.getItem("auth-token");
  return loggedIn ? children : <Navigate to="/login" />;
};

const AuthRoute = ({ children }) => {
  const loggedIn = localStorage.getItem("auth-token");
  return loggedIn ? <Navigate to="/browse" /> : children;
};

function App() {
  return (
    <BrowserRouter nativeWindowOpen={true}>
      <Routes>
        <Route
          path="/login"
          element={
            <AuthRoute>
              <SignIn />
            </AuthRoute>
          }
        />
        <Route
          path="/register"
          element={
            <AuthRoute>
              <SignUp />
            </AuthRoute>
          }
        />
        <Route
          path="/"
          element={
            <AuthRoute>
              <HomePage />
            </AuthRoute>
          }
        />
        <Route element={<WithNav />}>
          <Route path="/game/:id" element={<GamePage />} />
          <Route
            path="/browse"
            element={
              <UnauthRoute>
                <BrowsePage />
              </UnauthRoute>
            }
          />
          <Route
            path="/library"
            element={
              <UnauthRoute>
                <LibraryPage />
              </UnauthRoute>
            }
          />
          <Route
            path="/community"
            element={
              <UnauthRoute>
                <CommunityPage />
              </UnauthRoute>
            }
          />
          <Route
            path="/profile/:username"
            element={
              <UnauthRoute>
                <ProfilePage />
              </UnauthRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <UnauthRoute>
                <ProfilePage />
              </UnauthRoute>
            }
          />
          <Route
            path="/panel"
            element={
              <UnauthRoute>
                <PanelPage />
              </UnauthRoute>
            }
          />
          <Route
            path="/panel/publish"
            element={
              <UnauthRoute>
                <PanelPublishPage />
              </UnauthRoute>
            }
          />
          <Route
            path="/panel/:id"
            element={
              <UnauthRoute>
                <PanelGamePage />
              </UnauthRoute>
            }
          />
          <Route
            path="/panel/settings/:id"
            element={
              <UnauthRoute>
                <PanelSettingsPage />
              </UnauthRoute>
            }
          />
          <Route
            path="/panel/analytics/:id"
            element={
              <UnauthRoute>
                <PanelAnalyticsPage />
              </UnauthRoute>
            }
          />
          <Route path="*" element={<Navigate to="/login" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
