/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db, backEndUrl, backApiKey } from "../../../config";
import { getDoc, doc } from "firebase/firestore";
import {
  signInWithPopup,
  GoogleAuthProvider,
  GithubAuthProvider,
  signInWithEmailAndPassword,
} from "firebase/auth";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  var navigate = useNavigate();
  async function checkUserExists(userId) {
    const docSnapshot = await getDoc(doc(db, "USERS", userId));
    return docSnapshot.exists();
  }

  async function addDataToDatabase(user, userEmail) {
    const emailToStore = userEmail || user.email;
    try {
      const response = await fetch(`${backEndUrl}/registerOauth`, {
        method: "POST",
        headers: {
          "x-api-key": backApiKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: user.displayName,
          email: emailToStore,
          userId: user.uid,
        }),
      });

      if (response.status !== 201) {
        console.error("Error adding user data: ", response.error);
        return;
      }

      const authToken = response.token;
      localStorage.setItem("auth-token", authToken);
    } catch (error) {
      console.error("Error adding user data: ", error);
      let errorMessage = "An error occurred";
      switch (error.code) {
        case "auth/email-already-in-use":
          errorMessage = "Email already in use";
          break;
        case "auth/invalid-email":
          errorMessage = "Invalid email address";
          break;
        case "auth/weak-password":
          errorMessage = "Password is too weak";
          break;
        default:
          errorMessage = "An error occurred";
      }
      setErrorMessage(errorMessage);
    }
  }

  const handleGithubAuth = async () => {
    const provider = new GithubAuthProvider();
    provider.addScope("user");
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userEmail = user.email;
      const userExists = await checkUserExists(user.uid);

      if (!userExists) {
        localStorage.setItem("email", userEmail);
        await addDataToDatabase(user, userEmail);
      }
      const authToken = await user.getIdToken();
      localStorage.setItem("auth-token", authToken);
      navigate("/browse");
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoogleAuth = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    provider.addScope("email");

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userEmail = user.email;
      const userExists = await checkUserExists(user.uid);

      if (!userExists) {
        localStorage.setItem("email", userEmail);
        await addDataToDatabase(user, userEmail);
      }
      const authToken = await user.getIdToken();
      localStorage.setItem("auth-token", authToken);
      navigate("/browse");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const authToken = await user.getIdToken();
      localStorage.setItem("auth-token", authToken);
      navigate("/browse");
    } catch (error) {
      let errorMessage = "An error occurred";
      switch (error.code) {
        case "auth/invalid-email":
          errorMessage = "Invalid email address";
          break;
        case "auth/wrong-password":
          errorMessage = "Incorrect password";
          break;
        case "auth/user-not-found":
          errorMessage = "User not found";
          break;
        case "auth/missing-password":
          errorMessage = "Missing password";
          break;
        default:
          errorMessage = "An error occurred";
      }
      setErrorMessage(errorMessage);
    }
  };

  return (
    <div>
      <div className="flex h-screen items-center justify-center bg-black">
        <div className="flex max-w-4xl w-full bg-white rounded-xl shadow-2xl shadow-purple-500">
          <div className="hidden md:block w-1/2">
            <img
              src="Auth/signin_image.jpg"
              alt="Login Image"
              className="object-cover h-full w-full rounded-l-lg"
            />
          </div>
          <div className="w-full md:w-1/2 p-6 flex flex-col items-center mt-16">
            <img
              src="Logos/black_logo_transparent_bg.PNG"
              alt="Logo"
              className="w-40 h-20 mb-4"
            />
            <h1 className="text-3xl font-poppins font-bold text-primary text-center">
              Welcome back!
            </h1>
            <h1 className="text-sm mb-10 text-center text-gray-500">
              Please enter your details
            </h1>
            {errorMessage && (
              <p className="text-red-500 font-bold text-sm mb-4">{errorMessage}</p>
            )}
            <form onSubmit={handleSubmit} className="w-full">
              <div className="mb-4">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="w-full px-4 py-2 rounded-xl shadow-md focus:outline-none focus:shadow-outline "
                  id="email"
                  placeholder="Enter your email"
                />
              </div>
              <div className="mb-4">
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  className="w-full px-4 py-2 rounded-xl shadow-md focus:outline-none focus:shadow-outline"
                  id="password"
                  placeholder="Enter your password"
                />
              </div>
              <div className="flex justify-center items-center mb-6 bg-gray-900 rounded-full shadow-2xl hover:shadow-purple-800 transition transform hover:-translate-y-0.5">
                <button
                  type="submit"
                  className="bg-primary text-white px-6 py-2 rounded-full focus:outline-none hover:bg-primary-dark"
                >
                  Sign in
                </button>
              </div>
            </form>
            <div className="flex items-center my-4 w-full">
              <div className="flex-1 border-t border-gray-300"></div>
              <p className="mx-4 text-sm">Or sign in with</p>
              <div className="flex-1 border-t border-gray-300"></div>
            </div>
            <div className="flex items-center justify-center space-x-4 mt-3">
              <button
                onClick={handleGoogleAuth}
                className="flex items-center py-2 px-4 text-base rounded-full bg-white hover:bg-gray-200 text-gray-500 border border-transparent hover:border-transparent hover:text-gray-700 shadow-md hover:shadow-2xl hover:shadow-purple-800 font-medium transition transform hover:-translate-y-0.5"
              >
                <svg
                  className="px-2"
                  xmlns="http://www.w3.org/2000/svg"
                  xwidth="20"
                  height="20"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="black"
                    d="M21.456 10.154c.123.659.19 1.348.19 2.067c0 5.624-3.764 9.623-9.449 9.623A9.841 9.841 0 0 1 2.353 12a9.841 9.841 0 0 1 9.844-9.844c2.658 0 4.879.978 6.583 2.566l-2.775 2.775V7.49c-1.033-.984-2.344-1.489-3.808-1.489c-3.248 0-5.888 2.744-5.888 5.993c0 3.248 2.64 5.998 5.888 5.998c2.947 0 4.953-1.685 5.365-3.999h-5.365v-3.839h9.26Z"
                  />
                </svg>
                Google
              </button>
              <button
                onClick={handleGithubAuth}
                className="flex items-center px-4 text-base rounded-full bg-white hover:bg-gray-200 text-gray-500 border border-transparent hover:border-transparent hover:text-gray-700 hover:shadow-2xl font-medium shadow-2xl hover:shadow-purple-800 transition transform hover:-translate-y-0.5"
              >
                <svg
                  className="px-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="42"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="black"
                    d="M12 2C6.48 2 2 6.48 2 12c0 4.42 2.88 8.17 6.86 9.49c.5.09.68-.22.68-.48v-1.68c-2.8.61-3.39-1.35-3.39-1.35c-.46-1.17-1.12-1.48-1.12-1.48c-.91-.62.07-.61.07-.61c1.01.07 1.54 1.03 1.54 1.03c.9 1.54 2.36 1.1 2.93.84c.09-.65.35-1.1.63-1.35c-2.22-.25-4.55-1.11-4.55-4.93c0-1.09.39-1.98 1.03-2.68c-.1-.25-.45-1.27.1-2.64c0 0 .84-.27 2.75 1.02c.8-.22 1.65-.33 2.5-.33c.85 0 1.7.11 2.5.33c1.91-1.29 2.75-1.02 2.75-1.02c.55 1.37.2 2.39.1 2.64c.64.7 1.03 1.59 1.03 2.68c0 3.83-2.34 4.68-4.56 4.93c.36.31.68.92.68 1.85v2.74c0 .26.18.57.69.48C19.13 20.16 22 16.41 22 12C22 6.48 17.52 2 12 2Z"
                  />
                </svg>
                GitHub
              </button>
            </div>
            <p className="text-sm mt-6 text-center">
              Don't have an account yet?
              <a
                href="/register"
                className="text-primary font-semibold ml-2 hover:underline"
              >
                Sign up
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
